import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonType} from '../../enums/button-enum';
import {IconEnum} from '../../enums/icon-enum';
import {DatePipe} from '@angular/common';
import {IconButtonComponent} from '../icon-button/icon-button.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatSort, MatSortHeader, Sort} from '@angular/material/sort';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ButtonComponent} from '../button/button.component';
import {MatDialog} from '@angular/material/dialog';
import {Router, RouterLink} from '@angular/router';
import {RouteConstants} from '../../constants/route-constants';
import {WsComponentResponseDto, WsParameterRequestDto, WsParameterResponseDto, WsRuleResponseDto} from '@fertirob/fertirob-api';
import {SelectionBarComponent} from '../selection-bar/selection-bar.component';
import {EmptyStateListComponent} from '../empty-state-list/empty-state-list.component';
import {FilterOptionWrapperComponent} from '../filter-option-wrapper/filter-option-wrapper.component';
import {HeadlineComponent} from '../headline/headline.component';
import {filter, map, Observable} from 'rxjs';
import {LeavePageOverlayComponent} from '../leave-page-overlay/leave-page-overlay.component';
import {DeleteOverlayComponent} from "../delete-overlay/delete-overlay.component";
import {ParameterDetailsService} from '../../../modules/services/parameter-details.service';
import {FeedbackType} from '../../enums/feedback-type';
import {FeedbackService} from '../../services/feedback.service';
import {CustomPaginatorComponent} from '../custom-paginator/custom-paginator.component';
import {InputWrapperComponent} from '../input/input-wrapper/input-wrapper.component';

@Component({
  selector: 'app-parameter-list',
  standalone: true,
  imports: [
    DatePipe,
    IconButtonComponent,
    MatCheckbox,
    MatSort,
    MatSortHeader,
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    RouterLink,
    SelectionBarComponent,
    EmptyStateListComponent,
    FilterOptionWrapperComponent,
    HeadlineComponent,
    CustomPaginatorComponent,
    InputWrapperComponent
  ],
  templateUrl: './parameter-list.component.html',
  styleUrl: './parameter-list.component.scss'
})
export class ParameterListComponent implements OnInit {
  @Input() component: WsComponentResponseDto | undefined | null = undefined;
  @Input() set parametersInput(parameters: WsParameterResponseDto[] | undefined | null) {
    if (parameters) {
      this.parameters = parameters;
    }
  }
  @Input() isReadOnlyMode: boolean = false;
  @Input() disableAdd: boolean = false;

  @Output() onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  public parameters: WsParameterResponseDto[] | undefined = [];
  public parameterIdToEdit: string | undefined = undefined;

  public parameterForm: FormGroup;

  public checkedParameters: string[] = [];

  public selectionMode: boolean = false;
  public filterOptions: string[] = [];

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  constructor(private _fb: FormBuilder,
              private _dialog: MatDialog,
              private _feedbackService: FeedbackService,
              private _parameterDetailsService: ParameterDetailsService,
              private _translate: TranslateService) {
    this.parameterForm = this._fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      value: [''],
      parentId: ['']
    });
  }

  ngOnInit(): void {
    this.getFilterOptions();
  }

  public getFilterOptions(): void {
    this.filterOptions = ['Alle', 'Einer', 'Beide'];
  }

  public onSearchTermChange(searchTerm: string): void {
    //TODO call service
  }

  public onFilterOptionChange(filterOption: string): void {
    //TODO call service
  }

  public onCheckboxCheck(checked: boolean, paramId: string | undefined): void {
    if (!paramId) return;
    if (checked) {
      this.checkedParameters.push(paramId);
    } else {
      this.checkedParameters = this.checkedParameters.filter(param => param !== paramId);
    }
  }

  public onCheckAll(checked: boolean): void {
    this.checkedParameters = [];
    if (checked) {
      this.parameters?.forEach((param) => {
        if (param.id) {
          this.checkedParameters.push(param.id);
        }
      })
    }
  }

  public isChecked(paramId?: string): boolean {
    if (!paramId) return false;
    return this.checkedParameters.includes(paramId);
  }

  public areAllChecked(): boolean {
    if (this.checkedParameters.length === 0) return false;
    return this.checkedParameters.length === this.parameters?.length;
  }

  public areSomeChecked(): boolean {
    if (this.checkedParameters.length === 0) return false;
    if (!this.parameters) return false;
    return this.checkedParameters.length < this.parameters?.length;
  }

  public sortData($event: Sort) {
    this.onSortChange.emit($event);
  }

  public toggleSelectionMode(mode: boolean) {
    this.selectionMode = mode;
    if (!this.selectionMode) {
      this.checkedParameters = [];
    }
  }

  public onDownload(): void {
    //TODO download selected items
  }

  public deleteOverlay(value?: WsParameterResponseDto): void {
    const dialogRef = this._dialog.open(DeleteOverlayComponent);
    dialogRef.componentInstance.headline = this._translate.instant('modules.delete.deleteParameters');
    if (value) {
      dialogRef.componentInstance.values = value!.name;
    } else {
      dialogRef.componentInstance.values = this.parameters?.filter(param => this.checkedParameters.includes(param.id ?? '')).map(param => param.name).join(', ');
    }

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (value?.id) {
          await this._parameterDetailsService.deleteSingleParameter(value.id);
        } else {
          await this._parameterDetailsService.deleteMultiParameters(this.checkedParameters);
        }
        this.checkedParameters = [];
      }
    })
  }

  get isEmpty(): boolean {
    return this.parameters?.length === 0;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.parameterForm.dirty) {
      const dialogRef = this._dialog.open(LeavePageOverlayComponent);
      return dialogRef.afterClosed().pipe(map(result => result === true));
    }
    return true;
  }

  public onEditParameter(param: WsParameterResponseDto) {
    this.parameterIdToEdit = param.id;
    this.parameterForm.patchValue({
      name: param.name,
      description: param.description,
      value: param.value,
      parentId: param.parentId
    });
    this.parameterForm.markAsPristine();
  }

  public onAddParameter(): void {
    let updatedParameter: WsParameterRequestDto = {
      parentId: this.parameterForm.value.parentId,
      name: this.parameterForm.value.name,
      description: this.parameterForm.value.description,
      value: this.parameterForm.value.value
    }
    if (this.parameterIdToEdit) {
      this._parameterDetailsService.updateParameter(this.parameterIdToEdit, updatedParameter);
      this.parameterIdToEdit = undefined;
    } else {
      updatedParameter.parentId = this.component?.id??''
      this._parameterDetailsService.saveParameter(updatedParameter);
    }
    this.parameterForm.reset();
  }

  get disableAddParameterButton(): boolean {
    return this.parameterForm.invalid || this.parameterForm.pristine;
  }

  public onCancel() {
    this.parameterIdToEdit = undefined;
    this.parameterForm.reset();
    this.parameterForm.markAsPristine();
  }

  get showForm(): boolean {
    if (this.isReadOnlyMode) {
      return false;
    }
    if (this.disableAdd) {
      return this.parameterIdToEdit !== undefined;
    } else {
      return true;
    }
  }

  protected readonly RouteConstants = RouteConstants;
}
