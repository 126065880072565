@if (showForm) {
  <app-headline [title]="(parameterIdToEdit ? 'modules.editParameters' : 'modules.addNewParameters') | translate"></app-headline>
  <form class="horizontal-form with-labels" [formGroup]="parameterForm">
    <app-input-wrapper [id]="'name'" [label]="'default.name' | translate"
                       [control]="parameterForm.controls['name']">
      <input type="text" id="name" formControlName="name"
             [placeholder]="'default.inputPlaceholder' | translate">
    </app-input-wrapper>
    <app-input-wrapper [id]="'description'" [label]="'default.description' | translate"
                       [control]="parameterForm.controls['description']">
      <input type="text" id="description" formControlName="description"
             [placeholder]="'default.inputPlaceholder' | translate">
    </app-input-wrapper>
    <app-input-wrapper [id]="'value'" [label]="'default.newComment' | translate"
                       [control]="parameterForm.controls['value']">
      <input type="number" id="value" formControlName="value"
             [placeholder]="'default.optional' | translate">
    </app-input-wrapper>
    @if (parameterForm.dirty || parameterIdToEdit) {
      <app-button (click)="onCancel()" [type]="ButtonType.SECONDARY"
                  [label]="'default.cancel' | translate"></app-button>

    }
    <app-button (click)="onAddParameter()"
                [type]="ButtonType.PRIMARY"
                [label]="(parameterIdToEdit ? 'default.save' : 'default.create') | translate"
                [isDisabled]="disableAddParameterButton"></app-button>
  </form>
}
<app-headline [title]="'modules.parameterList' | translate">
  <app-button [isSmall]="true"
              [isDisabled]="isEmpty || isReadOnlyMode"
              [label]="'select.selectParameters' | translate"
              (click)="toggleSelectionMode(true)"
              [type]="ButtonType.SECONDARY"></app-button>
</app-headline>
<!--<app-filter-option-wrapper
  [searchPlaceholder]="'modules.searchComponents' | translate"
  [filterOptions]="filterOptions"
  (searchTerm)="onSearchTermChange($event)"
  (selectedOption)="onFilterOptionChange($event)"
></app-filter-option-wrapper>-->
@if (selectionMode) {
  <app-selection-bar [selectedAmount]="checkedParameters.length"
                     (onChangeSelectionMode)="toggleSelectionMode(false)"
                     (onTriggerDelete)="deleteOverlay()"
                     (onTriggerDownload)="onDownload()"
  ></app-selection-bar>
}
@if (isEmpty) {
  <app-empty-state-list
    [emptyStateText]="'modules.modulesParametersEmptyState' | translate"></app-empty-state-list>
} @else {
  <table matSort (matSortChange)="sortData($event)">
    <tbody>
    <tr>
      <th>
        <div class="flex-content-wrapper">
          @if (!isReadOnlyMode && selectionMode) {
            <mat-checkbox (change)="onCheckAll($event.checked)"
                          [checked]="areAllChecked()"
                          [indeterminate]="areSomeChecked()">
            </mat-checkbox>
          }
          {{ 'default.name' | translate }}
        </div>
      </th>
      <th>{{ 'default.description' | translate }}</th>
      <th>{{ 'default.newComment' | translate }}</th>
      <th class="table-head-sort"
          mat-sort-header="lastModified">{{ 'default.updatedDate' | translate }}
      </th>
      <th>{{ 'default.edit' | translate }}</th>
    </tr>
      @for (param of parameters; track param.id; let index = $index) {
        <tr>
          <td>
            <div class="flex-content-wrapper">
              @if (!isReadOnlyMode && selectionMode) {
                <mat-checkbox [checked]="isChecked(param.id)"
                              (change)="onCheckboxCheck($event.checked, param.id)">
                </mat-checkbox>
              }
              {{ param.name }}
            </div>
          </td>
          <td>{{ param.description }}</td>
          <td>
              {{param.value ? param.value : 'default.noEntry' | translate}}
          </td>
          <td>{{ param.createdDate | date }}</td>
          <td class="icon-cell">
            <div class="flex-content-wrapper">
              <app-icon-button [type]="ButtonType.PLAIN" [isDisabled]="isReadOnlyMode"
                               [iconName]="IconEnum.DELETE" (click)="deleteOverlay(param)"></app-icon-button>
              <app-icon-button (click)="onEditParameter(param)" [isDisabled]="isReadOnlyMode || (parameterIdToEdit !== undefined)"
                               [type]="ButtonType.PLAIN"
                               [iconName]="IconEnum.EDIT"></app-icon-button>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
}
