import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {FileResourceApiService} from '@fertirob/fertirob-api';

@Injectable({
  providedIn: 'root'
})
export class FileResourcesService {

  constructor(
    private _fileResourceApiService: FileResourceApiService
  ) {

  }

  public downloadFile = async (id: string): Promise<Blob> => {
    try {
      return await firstValueFrom(this._fileResourceApiService.downloadFileResource(id));
    } catch (err) {
      throw err;
    }
  }
}
