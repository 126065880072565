<app-parameter-list [isReadOnlyMode]="module?.readonly??false"
                    [parametersInput]="parameters$ | async"
                    [disableAdd]="true"
                    (onSortChange)="sortData($event)"></app-parameter-list>
<app-custom-paginator
  (pageChanged)="onChangePage($event)"
  [totalElements]="totalCount"
  [currentPage]="currentPage"
  [pageSize]="pageSize"
></app-custom-paginator>
