import {Injectable} from '@angular/core';
import {
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum, WsDocumentLogResponseDto,
  WsModuleResponseDto,
  WsParameterResponseDto,
  WsParameterValueResponseDto,
  WsProjectResponseDto,
  WsRuleResponseDto,
  WsDocumentLogResponseDtoActionEnum, WsDocumentCommentResponseDto
} from '@fertirob/fertirob-api';
import {UiDocuments} from "../../documents/classes/ui-documents";
import {UiComment} from "../classes/ui-comment";

@Injectable({
  providedIn: 'root'
})
export class MockFixturesService {

  constructor() {
  }

  public mockParameters(): WsParameterResponseDto[] {
    const uiParameter1: WsParameterResponseDto = {
      createdDate: new Date(),
      name: "H_BPP",
      value: 10,
      description: "Höhe Bipolarplatte",
      id: "1",
      updatedDate: new Date()
    }

    const uiParameter2: WsParameterResponseDto = {
      createdDate: new Date(),
      name: "L_BPP",
      description: "Länge Bipolarplatte",
      id: "2",
      updatedDate: new Date()
    }

    return [uiParameter1, uiParameter2]
  }

  public mockRules(): WsRuleResponseDto[] {
    const uiRule1: WsRuleResponseDto = {
      createdDate: new Date(),
      parameterName: "H_BPP",
      value: 'H_BPP*2',
      id: "1",
      updatedDate: new Date(),
    };

    const uiRule2: WsRuleResponseDto = {
      createdDate: new Date(),
      parameterName: "L_BPP",
      value: "(H_BPP*2)+L_BPP",
      id: "2",
      updatedDate: new Date(),
    };

    return [uiRule1, uiRule2];
  }

  public mockModule(): WsModuleResponseDto {
    return {
      id: "1",
      name: 'Example Module',
      description: 'Example Description',
      updatedDate: new Date(),
      rules: this.mockRules(),
      components: [{
        id: "1",
        name: 'Stack',
        updatedDate: new Date(),
        subComponents: [
          {
            id: "1.0",
            name: 'Assembly',
            updatedDate: new Date(),
            type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
            parameters: this.mockParameters(),
            subComponents: [
              {
                id: "1.1",
                name: 'Assembly',
                updatedDate: new Date(),
                type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                subComponents: [
                  {
                    id: "1.2.1",
                    name: 'Part',
                    updatedDate: new Date(),
                    type: WsComponentResponseDtoTypeEnum.PART,
                    subComponents: []
                  }
                ]
              },
              {
                id: "1.2",
                name: 'Assembly',
                updatedDate: new Date(),
                subComponents: [
                  {
                    id: "1.1.2",
                    name: 'Part',
                    updatedDate: new Date(),
                    type: WsComponentResponseDtoTypeEnum.PART,
                  }, {
                    id: "1.1.1",
                    name: 'Assembly',
                    updatedDate: new Date(),
                    type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                    subComponents: [
                      {
                        id: "1.1.1.1",
                        name: 'Part',
                        updatedDate: new Date(),
                        type: WsComponentResponseDtoTypeEnum.PART,
                        subComponents: []
                      },
                      {
                        id: "1.1.1.2",
                        name: 'Part',
                        updatedDate: new Date(),
                        type: WsComponentResponseDtoTypeEnum.PART,
                        subComponents: []
                      }
                    ]
                  }]
              }
            ]
          },
          {
            id: "2.0",
            name: 'Part',
            updatedDate: new Date(),
            type: WsComponentResponseDtoTypeEnum.PART,
            subComponents: []
          }
        ]
      }]
    }
  }

  public mockModuleList(): WsModuleResponseDto[] {
    return [
      {
        id: "1",
        name: 'Example Module 1',
        description: 'Example Description',
        updatedDate: new Date(),
        components: [{
          id: "1",
          name: 'Stack',
          updatedDate: new Date(),
          type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
          subComponents: [
            {
              id: "1.0",
              name: 'Assembly',
              updatedDate: new Date(),
              type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
              subComponents: [
                {
                  id: "1.1",
                  name: 'Assembly',
                  updatedDate: new Date(),
                  type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                  subComponents: [
                    {
                      id: "1.2.1",
                      name: 'Part',
                      updatedDate: new Date(),
                      type: WsComponentResponseDtoTypeEnum.PART,
                      subComponents: []
                    }
                  ],
                  parameters: [
                    {
                      id: '3',
                      name: 'Parameter 3',
                      value: 3,
                      description: 'Description 3',
                      updatedDate: new Date()
                    }
                  ]
                },
                {
                  id: "1.2",
                  name: 'Assembly',
                  updatedDate: new Date(),
                  subComponents: [
                    {
                      id: "1.1.2",
                      name: 'Part',
                      updatedDate: new Date(),
                      type: WsComponentResponseDtoTypeEnum.PART,
                    }, {
                      id: "1.1.1",
                      name: 'Assembly',
                      updatedDate: new Date(),
                      type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                      subComponents: [
                        {
                          id: "1.1.1.1",
                          name: 'Part',
                          updatedDate: new Date(),
                          type: WsComponentResponseDtoTypeEnum.PART,
                          subComponents: []
                        },
                        {
                          id: "1.1.1.2",
                          name: 'Part',
                          updatedDate: new Date(),
                          type: WsComponentResponseDtoTypeEnum.PART,
                          subComponents: []
                        }
                      ]
                    }]
                }
              ],
              parameters: [
                {
                  id: '4',
                  name: 'Parameter 4',
                  description: 'Description 4',
                  updatedDate: new Date()
                }
              ]
            },
            {
              id: "2.0",
              name: 'Part',
              updatedDate: new Date(),
              type: WsComponentResponseDtoTypeEnum.PART,
              subComponents: [],
              parameters: [
                {
                  id: '1',
                  name: 'Parameter 1',
                  description: 'Description 1',
                  value: 1,
                  updatedDate: new Date()
                },
                {
                  id: '2',
                  name: 'Parameter 2',
                  description: 'Description 2',
                  updatedDate: new Date()
                }
              ]
            }
          ],
        }]
      }, {
        id: "2",
        name: 'Example Module 2',
        description: 'Example Description',
        updatedDate: new Date(),
        components: [
          {
            id: "1",
            name: 'Stack',
            type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
            updatedDate: new Date(),
            subComponents: [
              {
                id: "1.0",
                name: 'Assembly',
                updatedDate: new Date(),
                type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                subComponents: [
                  {
                    id: "1.1",
                    name: 'Assembly',
                    updatedDate: new Date(),
                    type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                    subComponents: [
                      {
                        id: "1.2.1",
                        name: 'Part',
                        updatedDate: new Date(),
                        type: WsComponentResponseDtoTypeEnum.PART,
                        subComponents: []
                      }
                    ],
                    parameters: [
                      {
                        id: '3',
                        name: 'Parameter 3',
                        value: 3,
                        description: 'Description 3',
                        updatedDate: new Date()
                      }
                    ]
                  },
                  {
                    id: "1.2",
                    name: 'Assembly',
                    updatedDate: new Date(),
                    subComponents: [
                      {
                        id: "1.1.2",
                        name: 'Part',
                        updatedDate: new Date(),
                        type: WsComponentResponseDtoTypeEnum.PART,
                      }, {
                        id: "1.1.1",
                        name: 'Assembly',
                        updatedDate: new Date(),
                        type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                        subComponents: [
                          {
                            id: "1.1.1.1",
                            name: 'Part',
                            updatedDate: new Date(),
                            type: WsComponentResponseDtoTypeEnum.PART,
                            subComponents: []
                          },
                          {
                            id: "1.1.1.2",
                            name: 'Part',
                            updatedDate: new Date(),
                            type: WsComponentResponseDtoTypeEnum.PART,
                            subComponents: []
                          }
                        ]
                      }]
                  }
                ],
                parameters: [
                  {
                    id: '4',
                    name: 'Parameter 4',
                    description: 'Description 4',
                    updatedDate: new Date()
                  }
                ]
              },
              {
                id: "2.0",
                name: 'Part',
                updatedDate: new Date(),
                type: WsComponentResponseDtoTypeEnum.PART,
                subComponents: [],
                parameters: [
                  {
                    id: '1',
                    name: 'Parameter 1',
                    description: 'Description 1',
                    value: 1,
                    updatedDate: new Date()
                  },
                  {
                    id: '2',
                    name: 'Parameter 2',
                    description: 'Description 2',
                    updatedDate: new Date()
                  }
                ]
              }
            ],
          }
        ]
      }, {
        id: "3",
        name: 'Example Module 3',
        description: 'Example Description',
        updatedDate: new Date(),
        components: [{
          id: "1",
          name: 'Stack',
          updatedDate: new Date(),
          type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
          subComponents: [
            {
              id: "1.0",
              name: 'Assembly',
              updatedDate: new Date(),
              type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
              subComponents: [
                {
                  id: "1.1",
                  name: 'Assembly',
                  updatedDate: new Date(),
                  type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                  subComponents: [
                    {
                      id: "1.2.1",
                      name: 'Part',
                      updatedDate: new Date(),
                      type: WsComponentResponseDtoTypeEnum.PART,
                      subComponents: []
                    }
                  ],
                  parameters: [
                    {
                      id: '3',
                      name: 'Parameter 3',
                      value: 3,
                      description: 'Description 3',
                      updatedDate: new Date()
                    }
                  ]
                },
                {
                  id: "1.2",
                  name: 'Assembly',
                  updatedDate: new Date(),
                  subComponents: [
                    {
                      id: "1.1.2",
                      name: 'Part',
                      updatedDate: new Date(),
                      type: WsComponentResponseDtoTypeEnum.PART,
                    }, {
                      id: "1.1.1",
                      name: 'Assembly',
                      updatedDate: new Date(),
                      type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
                      subComponents: [
                        {
                          id: "1.1.1.1",
                          name: 'Part',
                          updatedDate: new Date(),
                          type: WsComponentResponseDtoTypeEnum.PART,
                          subComponents: []
                        },
                        {
                          id: "1.1.1.2",
                          name: 'Part',
                          updatedDate: new Date(),
                          type: WsComponentResponseDtoTypeEnum.PART,
                          subComponents: []
                        }
                      ]
                    }]
                }
              ],
              parameters: [
                {
                  id: '4',
                  name: 'Parameter 4',
                  description: 'Description 4',
                  updatedDate: new Date()
                }
              ]
            },
            {
              id: "2.0",
              name: 'Part',
              updatedDate: new Date(),
              type: WsComponentResponseDtoTypeEnum.PART,
              subComponents: [],
              parameters: [
                {
                  id: '1',
                  name: 'Parameter 1',
                  description: 'Description 1',
                  value: 1,
                  updatedDate: new Date()
                },
                {
                  id: '2',
                  name: 'Parameter 2',
                  description: 'Description 2',
                  updatedDate: new Date()
                }
              ]
            }
          ],
        }]
      }]
  }

  public mockProjectList(): WsProjectResponseDto[] {
    let module1: WsModuleResponseDto = {
      id: '1',
      name: 'Module 1'
    }
    let module2: WsModuleResponseDto = {
      id: '2',
      name: 'Module 2'
    }
    return [
      this.mockProject(),
      {
        id: "2",
        name: 'Example Project 2',
        description: 'Lorem Ipsum',
        updatedDate: new Date(),
        parameterValues: [],
        modules: [module1, module2]
      }, {
        id: "3",
        name: 'Example Project 3',
        description: 'Lorem Ipsum',
        updatedDate: new Date(),
        parameterValues: [],
        modules: [module1, module2]
      }]
  }

  public mockProject(): WsProjectResponseDto {
    let parameter: WsParameterValueResponseDto = {
      id: '1',
      parameterId: '2',
      value: 200,
    }
    return {
      id: "1",
      name: 'Example Project 1',
      description: 'Lorem Ipsum',
      updatedDate: new Date(),
      modules: this.mockProjectModuleList(),
      parameterValues: [parameter]
    }
  }

  public mockProjectModuleList(): WsModuleResponseDto[] {
    let parameter1: WsParameterResponseDto = {
      id: '1',
      name: 'Parameter 1',
      description: 'Lorem Ipsum',
      value: 10,
      createdDate: new Date(),
      updatedDate: new Date(),
    }
    let parameter2: WsParameterResponseDto = {
      id: '2',
      name: 'Parameter 2',
      description: 'Lorem Ipsum',
      createdDate: new Date(),
      updatedDate: new Date(),
    }
    let subComponent: WsComponentResponseDto = {
      id: '2',
      type: WsComponentResponseDtoTypeEnum.PART,
      name: 'Part',
      description: 'Lorem Ipsum',
      parameters: [parameter2],
      createdDate: new Date(),
      updatedDate: new Date(),
    }
    let subComponent1: WsComponentResponseDto = {
      id: '2',
      type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
      name: 'Assembly',
      description: 'Lorem Ipsum',
      subComponents: [subComponent]
    }
    let component: WsComponentResponseDto = {
      id: '1',
      type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
      name: 'Assembly',
      description: 'Lorem Ipsum',
      subComponents: [subComponent1],
      parameters: [parameter1],
      createdDate: new Date(),
      updatedDate: new Date(),
    }
    return [{
      id: '1',
      name: 'Module 1',
      description: 'Lorem Ipsum',
      components: [component],
      rules: []
    }]
  }


  public mockComponent(id: string): WsComponentResponseDto {
    let parentComponent3: WsComponentResponseDto = {
      id: "3",
      name: 'Parent Component 3',
      description: 'Example Description',
      updatedDate: new Date(),
      type: WsComponentResponseDtoTypeEnum.ASSEMBLY
    }
    let parentComponent2: WsComponentResponseDto = {
      id: "2",
      name: 'Parent Component 2',
      description: 'Example Description',
      updatedDate: new Date(),
      parentComponent: parentComponent3,
      type: WsComponentResponseDtoTypeEnum.ASSEMBLY
    }
    let parentComponent1: WsComponentResponseDto = {
      id: "1",
      name: 'Parent Component 1',
      description: 'Example Description',
      updatedDate: new Date(),
      parentComponent: parentComponent2,
      type: WsComponentResponseDtoTypeEnum.ASSEMBLY
    }
    let module: WsModuleResponseDto = {
      id: "1",
      name: 'Module',
      description: 'Example Description',
      updatedDate: new Date(),
    }
    return {
      id: id,
      name: 'Example Assembly' + id,
      description: 'Example Description',
      parentComponent: parentComponent1,
      module: module,
      updatedDate: new Date(),
      type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
      subComponents: [
        {
          id: "1.0",
          name: 'Assembly',
          description: 'Example Description',
          updatedDate: new Date(),
          type: WsComponentResponseDtoTypeEnum.ASSEMBLY,
          subComponents: [
            {
              id: "1.1.0",
              name: 'Part',
              description: 'Example',
              updatedDate: new Date(),
              type: WsComponentResponseDtoTypeEnum.PART,
              subComponents: []
            }
          ]
        },
        {
          id: "1.2.0",
          name: 'Part',
          description: 'Example Description',
          updatedDate: new Date(),
          type: WsComponentResponseDtoTypeEnum.PART,
          subComponents: []
        }
      ]
    }
  }

  public mockDocumentList(): UiDocuments[] {
    return [
      {
        id: '1',
        name: 'Example Document 1',
        responsibleUser: 'Lars.Heinecke@gmail.com',
        updatedDate: new Date(),
        isFile: true,
        size: 78,
        deletedDate: new Date(),
        origin: "Project ABC/Sammlung 1/Modul 1/Dataset 3/Tape 3",
        subDocuments: []
      },
      {
        id: '2',
        name: 'Example Document 2',
        responsibleUser: 'Helena.Frisch@gmail.com',
        updatedDate: new Date(),
        isFile: true,
        size: 83,
        deletedDate: new Date(),
        origin: "Project ABC/Sammlung 2/Modul 2",
        subDocuments: []
      },
      {
        id: '3',
        name: 'Example Folder 3',
        responsibleUser: 'Steven.Stahl@gmail.com',
        updatedDate: new Date(),
        isFile: false,
        size: 112,
        deletedDate: new Date(),
        origin: "Project ABC",
        subDocuments: [
          {
            id: '4',
            name: 'Example Folder 4',
            responsibleUser: 'Lars.Heinecke@gmail.com',
            updatedDate: new Date(),
            isFile: false,
            subDocuments: [
              {
                id: '5',
                name: 'Example Document 5',
                responsibleUser: 'Lars.Heinecke@gmail.com',
                updatedDate: new Date(),
                isFile: true,
              }
            ]
          }
        ]
      },
      {
        id: '6',
        name: 'Example Folder 6',
        responsibleUser: 'Steven.Stahl@gmail.com',
        updatedDate: new Date(),
        isFile: false,
        size: 112,
        deletedDate: new Date(),
        origin: "Project ABC",
        subDocuments: [
          {
            id: '7',
            name: 'Example Folder 7',
            responsibleUser: 'Lars.Heinecke@gmail.com',
            updatedDate: new Date(),
            isFile: false,
            subDocuments: [
              {
                id: '8',
                name: 'Example Document 8',
                responsibleUser: 'Lars.Heinecke@gmail.com',
                updatedDate: new Date(),
                isFile: true,
              }
            ]
          }
        ]
      }
    ]
  }

  public mockVersions(): WsDocumentLogResponseDto[]{
    return [
      {
        actorEmail: 'Lars.Heinecke@gmail.com',
        createdDate: new Date(),
        action: WsDocumentLogResponseDtoActionEnum.REMOVE
      },
      {
        actorEmail: 'susi.strolch@gmail.com',
        createdDate: new Date(),
      },
      {
        actorEmail: 'bat.man@gmail.com',
        createdDate: new Date(),
      },
      {
        actorEmail: 'guenther.jauch@gmail.com',
        createdDate: new Date(),
        action: WsDocumentLogResponseDtoActionEnum.RENAME
      },
      {
        actorEmail: 'peter.pan@gmail.com',
        createdDate: new Date(),
      },
      {
        actorEmail: 'tennis.ball@gmail.com',
        createdDate: new Date(),
      },
      {
        actorEmail: 'harry.potter@gmail.com',
        createdDate: new Date(),
      },
      {
        actorEmail: 'lucy.loo@gmail.com',
        createdDate: new Date(),
      },
      {
        actorEmail: 'scooby.doo@gmail.com',
        createdDate: new Date(),
      }
    ]
  }

  public mockComments(): WsDocumentCommentResponseDto[]{
    return [
      {
        id: '1',
        commenter: 'Lars.Heinecke@gmail.com',
        createdDate: new Date(),
        comment: 'Das Layout ist sehr elegant',
        replies: []
      },
      {
        id: '2',
        commenter: 'susi.strolch@gmail.com',
        createdDate: new Date(),
        comment: 'Das Layout ist sehr elegant',
        replies: [
          {
            id: '3',
            commenter: 'Lars.Heinecke@gmail.com',
            createdDate: new Date(),
            comment: 'Das Layout ist sehr elegant',
            replies: []
          }
        ]
      },
      {
        id: '4',
        commenter: 'bat.man@gmail.com',
        createdDate: new Date(),
        comment: 'Das Layout ist sehr elegant',
        replies: [
          {
            id: '5',
            commenter: 'Lars.Heinecke@gmail.com',
            createdDate: new Date(),
            comment: 'Das Layout ist sehr elegant',
            replies: []
          },
          {
            id: '6',
            commenter: 'susi.strolch@gmail.com',
            createdDate: new Date(),
            comment: 'Das Layout ist sehr elegant',
            replies: []
          },
          {
            id: '7',
            commenter: 'harry.potter@gmail.com',
            createdDate: new Date(),
            comment: 'Das Layout ist sehr elegant',
            replies: []
          }
        ]
      },
      {
        id: '8',
        commenter: 'guenther.jauch@gmail.com',
        createdDate: new Date(),
        comment: 'Das Layout ist sehr elegant',
        replies: []
      }
    ]
  }
}
