<div class="version-wrapper" [class.no-line]="noLine">
  <div class="version-header">
    <p class="date">{{ version?.createdDate | date: 'shortTime' }} | {{ version?.createdDate | date: 'shortDate' }}</p>
  </div>
  <p
    class="message">{{ '@' + (version?.actorEmail ? version?.actorEmail : 'fertirob.user') }} {{ 'documents.action.message.' + version?.action?.toLowerCase() | translate }}</p>
  @if (version?.action === WsDocumentLogResponseDtoActionEnum.UPLOAD_VERSION) {
    <button class="text-link" (click)="downloadFile()">{{ version?.document?.displayFileName }}</button>
  }
  @if (version?.action) {
    <div class="changelog-message">{{ ('documents.action.tag.' + version?.action?.toLowerCase() | translate) }}</div>
  }
</div>
