import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {ButtonType} from '../../../shared/enums/button-enum';
import {
  AssemblyTableRowComponent
} from '../../../modules/components/assembly-table-row/assembly-table-row.component';
import {DatePipe} from '@angular/common';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {
  PartTableRowComponent
} from '../../../modules/components/part-table-row/part-table-row.component';
import {ChipComponent} from '../../../shared/components/chip/chip.component';
import {
  InputWrapperComponent
} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {UiCompletedComponent} from '../../classes/ui-completed-component';
import {MatDialog} from '@angular/material/dialog';
import {
  ComponentDetailsOverlayComponent
} from '../component-details-overlay/component-details-overlay.component';
import {
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsParameterResponseDto,
  WsParameterValueResponseDto, WsParameterValuesRequestDto, WsProjectResponseDto, WsProjectUpdateRequestDto
} from '@fertirob/fertirob-api';
import {ChipType} from "../../../shared/enums/chip-enum";
import {ProjectDetailsService} from '../../services/project-details.service';
import {ButtonComponent} from '../../../shared/components/button/button.component';

@Component({
  selector: 'app-component-table-row-in-project',
  standalone: true,
  imports: [
    AssemblyTableRowComponent,
    DatePipe,
    IconButtonComponent,
    PartTableRowComponent,
    ChipComponent,
    InputWrapperComponent,
    TranslateModule,
    ReactiveFormsModule,
    ButtonComponent
  ],
  templateUrl: './component-table-row-in-project.component.html',
  styleUrl: './component-table-row-in-project.component.scss'
})
export class ComponentTableRowInProjectComponent implements OnInit {
  @Input() component?: WsComponentResponseDto;
  @Input() projectParameters?: WsParameterValueResponseDto[];
  @Input() enableDelete?: boolean = false;
  @Output() onFormChange: EventEmitter<UiCompletedComponent> = new EventEmitter<UiCompletedComponent>();
  @Output() onFormDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onParameterValueChange: EventEmitter<WsParameterValuesRequestDto[]> = new EventEmitter<WsParameterValuesRequestDto[]>();

  public isComponentComplete: boolean = true;
  public parameterForm: FormGroup;
  public isSelected: boolean = false;
  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  constructor(private fb: FormBuilder,
              private _projectDetailsService: ProjectDetailsService,
              private _cd: ChangeDetectorRef,
              private _dialog: MatDialog) {
    this.parameterForm = this.fb.group({
      parameters: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.formSetUp();
  }

  get parameterArray(): FormArray {
    return this.parameterForm.get('parameters') as FormArray;
  }

  public nonRuleParameters(): Array<WsParameterResponseDto> {
    let parameters : Array<WsParameterResponseDto> = [];

    if (this.component && this.component.module) {
      this.component?.parameters?.forEach((parameter: WsParameterResponseDto) => {
        if (!this._projectDetailsService.hasParameterRule(parameter, this.component!.module!)) {
          parameters.push(parameter);
        }
      })
    }

    return parameters;
  }

  public formSetUp(): void {
    if (this.component && this.component.module) {

      let parameters = this.nonRuleParameters();
      parameters.forEach((parameter: WsParameterResponseDto) => {
        this.parameterArray.push(this.createParameter(parameter));
      })
    }
    this.emitFormState();

    this.parameterForm.valueChanges.subscribe(value => {
      this.emitFormState();
      this.onFormDirty.emit(true);
    });
  }

  public emitFormState(): void {
    this._cd.detectChanges();
    let completedComponent: UiCompletedComponent = {
      id: this.component?.id,
      isCompleted: this.isComponentComplete
    }
    this.emitChange(completedComponent);
  }

  public emitChange(completedComponent: UiCompletedComponent): void {
    this.onFormChange.emit(completedComponent);

    if (this.component?.id && this.hasParameters(this.component)) {
      let parameters: WsParameterValuesRequestDto[] = [];
      this.parameterForm.value.parameters.forEach((parameter: any) => {
        let parameterValue: WsParameterValuesRequestDto = {
          parameterId: parameter.serialNumber,
          value: parameter.value
        }
        parameters.push(parameterValue)
      })
      this.onParameterValueChange.emit(parameters);
    }
  }

  public emitDirty(event: any): void {
    this.onFormDirty.emit(true);
  }

  private createParameter = (parameter?: WsParameterResponseDto): FormGroup => {
    let parameterValue: number | null | undefined = parameter?.value;
    this.projectParameters?.forEach((projectParameter) => {
      if (parameter?.id === projectParameter.parameterId) {
        parameterValue = projectParameter.value;
      }
    })
    return this.fb.group({
      serialNumber: [parameter?.id],
      value: [parameterValue],
      valueDefault: [parameter?.value],
    });
  }

  public selectComponent(): void {
    this.isSelected = !this.isSelected;
  }

  public hasParameters(component?: WsComponentResponseDto): boolean {
    return !(!component || !component?.parameters);
  }

  public hasRules(): boolean {
    return this.nonRuleParameters().length > 0
  }

  get amountComplete(): string | undefined {
    let amount = 0;
    for (let control of this.parameterArray.controls) {
      if (control.value.value !== null) {
        amount++;
      }
    }
    this.isComponentComplete = amount === this.parameterArray.controls.length;
    return this.isComponentComplete ? undefined : amount + '/' + this.parameterArray.controls.length;
  }

  public onShowComponentDetails(): void {
    const dialogRef = this._dialog.open(ComponentDetailsOverlayComponent);
    dialogRef.componentInstance.component = this.component;
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  public hasFiles(): boolean {
    //TODO implement check when component has files attribute
    return true;
  }

  public parameterValueChange(event: WsParameterValuesRequestDto[]): void {
    this.onParameterValueChange.emit(event);
  }

  public onResetValue(index: number, parameterValue: number): void {
    this.parameterArray.at(index).get('value')?.patchValue(parameterValue)
  }

  public getParameterValue(index: number): number {
    return this.parameterArray.at(index).get('value')?.value
  }

  protected readonly ChipType = ChipType;
}
