import {Component, Input, OnInit} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {ButtonType} from '../../../shared/enums/button-enum';
import {ChipComponent} from '../../../shared/components/chip/chip.component';
import {DatePipe} from '@angular/common';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {
  WsComponentResponseDto,
  WsParameterResponseDto,
  WsProjectResponseDto, WsProjectResponseDtoStatusEnum,
  WsRuleResponseDto
} from '@fertirob/fertirob-api';
import {RouterLink} from '@angular/router';
import {ChipType} from "../../../shared/enums/chip-enum";
import {
  DeleteOverlayComponent
} from "../../../shared/components/delete-overlay/delete-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ModuleDetailsService} from '../../../modules/services/module-details.service';
import {ProjectDetailsService} from '../../services/project-details.service';
import {ExportProjectOverlayComponent} from '../export-project-overlay/export-project-overlay.component';
import {UiProjectExport} from '../../classes/ui-project-export';

@Component({
  selector: 'app-project-table-row',
  standalone: true,
  imports: [
    ChipComponent,
    DatePipe,
    IconButtonComponent,
    RouterLink
  ],
  templateUrl: './project-table-row.component.html',
  styleUrl: './project-table-row.component.scss'
})
export class ProjectTableRowComponent implements OnInit {
  @Input() project?: WsProjectResponseDto;
  public amountOfParameters: number = 0;

  constructor(private _dialog: MatDialog,
              private _projectDetailsService: ProjectDetailsService,
              private _translate: TranslateService) {
  }

  ngOnInit(): void {
    this.project?.modules?.forEach((module) => {
      if (module.components) {
        this.amountOfComponentParameters(module.components);
      }
    })
  }

  public amountOfComponentParameters(assemblies: WsComponentResponseDto[]): void {
    assemblies.forEach((assembly: WsComponentResponseDto) => {
      if (assembly.parameters) {
        this.amountOfParameters = this.amountOfParameters + assembly.parameters?.length;
        if (assembly.subComponents) {
          this.amountOfComponentParameters(assembly.subComponents);
        }
      }
    })
  }

  public exportProject() {
    const dialogRef = this._dialog.open(ExportProjectOverlayComponent);
    dialogRef.componentInstance.project = this.project;
  }

  public deleteOverlay() {
    const dialogRef = this._dialog.open(DeleteOverlayComponent)
    dialogRef.componentInstance.values = this.project!.name;
    dialogRef.componentInstance.headline = this._translate.instant('projects.delete.deleteProject');

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.project?.id) {
        this._projectDetailsService.deleteProject(this.project?.id);
      }
    })
  }

  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;
  protected readonly ChipType = ChipType;
  protected readonly WsProjectResponseDtoStatusEnum = WsProjectResponseDtoStatusEnum;
}
