<app-page-layout [noButtons]="true">
  <ng-container layout-main>
    <div class="spinner-overlay">
      @if (isLoading$ | async) {
        <app-spinner></app-spinner>
      }
      <div class="dashboard-tile-wrapper">
        @for (item of dashboardItems; track item.headline) {
          <app-dashboard-item
            [isLoading]="isLoading$ | async"
            [dashboardItem]="item"
            [class.half]="!item.subDashboardItems"
          ></app-dashboard-item>
        }
      </div>
    </div>
  </ng-container>
</app-page-layout>
