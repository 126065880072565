import { Component } from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {RouteConstants} from '../../constants/route-constants';
import {NavigationItemComponent} from '../navigation-item/navigation-item.component';
import {IconEnum} from '../../enums/icon-enum';
import {UiNavigationItem} from "../../classes/ui-navigation-item";

@Component({
  selector: 'app-main-navigation',
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    NavigationItemComponent
  ],
  templateUrl: './main-navigation.component.html',
  styleUrl: './main-navigation.component.scss'
})
export class MainNavigationComponent {
  public documentSubItems: UiNavigationItem[] = [{
    label: 'navigation.trash',
    route: '/' + RouteConstants.routeDocuments + '/' + RouteConstants.routeTrash
  }]

  public navItems: UiNavigationItem[] = [
    {
      label: 'navigation.dashboard',
      route: '/' + RouteConstants.routeDashboard,
      icon: IconEnum.DASHBOARD
    },
    {
      label: 'navigation.modules',
      route: '/' + RouteConstants.routeModules,
      icon: IconEnum.PRODUCTION_MODULE
    },
    {
      label: 'navigation.projects',
      route: '/' + RouteConstants.routeProjects,
      icon: IconEnum.PROJECT
    },
    {
      label: 'navigation.documents',
      route: '/' + RouteConstants.routeDocuments,
      subItems: this.documentSubItems,
      icon: IconEnum.DOCUMENTS
    }
  ]
  onLogOut(): void {
  }

  protected readonly RouteConstants = RouteConstants;
  protected readonly IconEnum = IconEnum;
}
