import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreadCrumbNavigationComponent} from '../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {PageLayoutComponent} from '../../../shared/components/page-layout/page-layout.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {ActivatedRoute, CanDeactivate, RouterLink} from '@angular/router';
import {Observable, of, Subject, takeUntil} from 'rxjs';
import {ProjectDetailsService} from '../../services/project-details.service';
import {TabBarComponent} from '../../../shared/components/tab-bar/tab-bar.component';
import {TabBarEnum} from '../../../shared/enums/tab-bar-enum';
import {ProjectGeneralComponent} from '../project-general/project-general.component';
import {UiTabBar} from '../../../shared/classes/ui-tab-bar';
import {ProjectInputsComponent} from '../project-inputs/project-inputs.component';
import {UiBreadCrumbItem} from '../../../shared/classes/ui-bread-crumb-item';
import {WsModuleResponseDto, WsProjectResponseDto} from '@fertirob/fertirob-api';
import {AsyncPipe} from '@angular/common';
import {MockFixturesService} from '../../../shared/services/mock-fixtures.service';

@Component({
  selector: 'app-page-project-details',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    HeadlineComponent,
    InputWrapperComponent,
    MatTab,
    MatTabGroup,
    PageLayoutComponent,
    ReactiveFormsModule,
    TranslateModule,
    RouterLink,
    TabBarComponent,
    ProjectGeneralComponent,
    ProjectInputsComponent,
    AsyncPipe
  ],
  templateUrl: './page-project-details.component.html',
  styleUrl: './page-project-details.component.scss'
})
export class PageProjectDetailsComponent implements OnInit, OnDestroy, CanDeactivate<any> {
  @ViewChild(ProjectGeneralComponent) projectGeneralComponent?: ProjectGeneralComponent;
  @ViewChild(ProjectInputsComponent) projectInputsComponent?: ProjectInputsComponent;

  public project$: Observable<WsProjectResponseDto | undefined> = this._projectDetailsService.getProject();
  public breadCrumbItems: UiBreadCrumbItem[] = [];
  private _notifier = new Subject<void>();
  protected readonly RouteConstants = RouteConstants;

  public statesList: UiTabBar[] = [];
  public activeState?: UiTabBar;

  constructor(private _route: ActivatedRoute,
              private _translate: TranslateService,
              private _projectDetailsService: ProjectDetailsService) {
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      if (params['id']) {
        this._projectDetailsService.fetchProject(params['id']);
      }
    });
    this.project$.pipe(takeUntil(this._notifier)).subscribe(project => {
      if (project) {
        this._translate.get([ 'projects.projects']).subscribe((translations => {
          this.breadCrumbItems = [
            {label: translations['projects.projects'], route: ['/', RouteConstants.routeProjects]},
            {label: project?.name},
          ];
        }))
        this.statesList = [
          {label: TabBarEnum.GENERAL, link: ['../', RouteConstants.routeGeneral]},
          {label: TabBarEnum.INPUTS, link: ['../', RouteConstants.routeInputs]},
          {label: TabBarEnum.DOCUMENTS, link: ['/', RouteConstants.routeDocuments, project?.id??''], isExternalLink: true}
        ];
      }
    })
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  get isGeneralTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.GENERAL;
  }

  get isInputsTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.INPUTS;
  }

  onChangeActiveState(activeState: UiTabBar): void {
    this.activeState = activeState;
  }

  canDeactivate(): Observable<boolean> | boolean {
    switch (this.activeState?.label) {
      case TabBarEnum.GENERAL:
        return this.projectGeneralComponent ? this.projectGeneralComponent.canDeactivate() : true;
      case TabBarEnum.INPUTS:
        return this.projectInputsComponent ? this.projectInputsComponent.canDeactivate() : true;
      default:
        return true;
    }
  }
}
