import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {OverlayComponent} from '../../../shared/components/overlay/overlay.component';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {MatFormField, MatPrefix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {CheckboxRowsComponent} from '../../../shared/components/checkbox-rows/checkbox-rows.component';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {ModuleListService} from '../../../modules/services/module-list.service';
import {WsModuleRequestDto, WsModuleResponseDto, WsProjectRequestDto} from '@fertirob/fertirob-api';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {map, Observable} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {CustomPaginatorComponent} from '../../../shared/components/custom-paginator/custom-paginator.component';

@Component({
  selector: 'app-add-project-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    InputWrapperComponent,
    OverlayComponent,
    ReactiveFormsModule,
    TranslateModule,
    HeadlineComponent,
    MatFormField,
    MatIcon,
    MatInput,
    MatPrefix,
    CheckboxRowsComponent,
    MatCheckbox,
    EmptyStateListComponent,
    AsyncPipe,
    CustomPaginatorComponent
  ],
  templateUrl: './add-project-overlay.component.html',
  styleUrl: './add-project-overlay.component.scss'
})
export class AddProjectOverlayComponent implements OnInit {
  public addForm: FormGroup;
  public modules$: Observable<WsModuleResponseDto[]> = this._moduleListService.getModuleList();

  constructor(private _fb: FormBuilder,
              public dialogRef: MatDialogRef<AddProjectOverlayComponent>,
              private _moduleListService: ModuleListService
  ) {
    this.addForm = this._fb.group({
      name: ['', Validators.required],
      description: [''],
      selectedModules: this._fb.array([], Validators.required),
    })
  }

  ngOnInit(): void {
    //Change $page-size in scss file, when changing page size in service
    this._moduleListService.setPageSize(4);
  }

  get pageSize(): number {
    return this._moduleListService.getPageSize;
  }

  get currentPage(): number {
    return this._moduleListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._moduleListService.getTotalCount;
  }

  get isEmpty(): Observable<boolean> {
    return this.modules$.pipe(
      map(modules => modules.length === 0)
    );
  }

  public onCheckChange(event: MatCheckboxChange) {
    const formArray: FormArray = this.addForm.get('selectedModules') as FormArray;
    if(event.checked) {
      formArray.push(new FormControl(event.source.value));
    }
    else{
      let i: number = 0;
      formArray.controls.forEach((ctrl: AbstractControl) => {
        if(ctrl.value == event.source.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  public isInList(moduleId: string | undefined): boolean {
    if (!moduleId) return false;
    let inList: boolean = false
    const formArray: FormArray = this.addForm.get('selectedModules') as FormArray;
    formArray.controls.forEach((ctrl: AbstractControl) => {
      if (ctrl.value == moduleId) {
        inList = true;
      }
    });
    return inList;
  }

  public onHideOverlay = (): void => {
    this.dialogRef.close(false);
  }

  public onSubmit = (): void => {
    let projectRequest: WsProjectRequestDto = {
      name: this.addForm.value.name,
      description: this.addForm.value.description,
      modules: this.addForm.value.selectedModules,
    }
    this.dialogRef.close(projectRequest);
  }

  public onChangePage(page: number): void {
    this._moduleListService.setCurrentPage(page);
  }

  get moreThanOnePage(): boolean {
    return this.totalCount > this.pageSize
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
