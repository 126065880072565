<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    <app-headline [title]="'projects.projectsOverview' | translate" [hideDivider]="true">
      <app-button (click)="onShowCreateModuleOverlay()" [type]="ButtonType.PRIMARY" [label]="'default.new' | translate"></app-button>
    </app-headline>
    @if (!(isEmpty | async) && !(isLoading$ | async)) {
      <div class="table table-grid">
        <div class="table-row table-header-row four-column">
          <div class="table-cell">{{ 'default.name' | translate }}</div>
          <div class="table-cell">{{ 'default.state' | translate }}</div>
          <div class="table-cell">{{ 'default.updatedDate' | translate }}</div>
          <div class="table-cell">{{ 'default.edit' | translate }}</div>
        </div>
        @for (project of (projects$ | async); track project.id) {
          <app-project-table-row [project]="project"></app-project-table-row>
        }
      </div>
      <app-custom-paginator
        (pageChanged)="onChangePage($event)"
        [totalElements]="totalCount"
        [currentPage]="currentPage"
        [pageSize]="pageSize"
      ></app-custom-paginator>
    }
    @if ((isEmpty | async) && !(isLoading$ | async)) {
      <app-empty-state-list [emptyStateText]="'projects.projectsEmptyState' | translate"></app-empty-state-list>
    }
    @if (isLoading$ | async) {
      <app-spinner></app-spinner>
    }
  </ng-container>
</app-page-layout>
