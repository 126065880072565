import {Component, Input, ViewChild} from '@angular/core';
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonType} from "../../../shared/enums/button-enum";
import {DatePipe} from "@angular/common";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatSort, MatSortHeader, Sort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {FilterOptionWrapperComponent} from '../../../shared/components/filter-option-wrapper/filter-option-wrapper.component';
import {ParameterListComponent} from '../../../shared/components/parameter-list/parameter-list.component';
import {WsComponentResponseDto, WsParameterRequestDto, WsParameterResponseDto} from '@fertirob/fertirob-api';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {RouterLink} from '@angular/router';
import {ComponentDetailsService} from '../../services/component-details.service';
import {map, Observable} from 'rxjs';
import {LeavePageOverlayComponent} from '../../../shared/components/leave-page-overlay/leave-page-overlay.component';
import {ParameterDetailsService} from '../../services/parameter-details.service';
import {CustomPaginatorComponent} from '../../../shared/components/custom-paginator/custom-paginator.component';

@Component({
  selector: 'app-component-details-parameters',
  standalone: true,
  imports: [
    ButtonComponent,
    HeadlineComponent,
    InputWrapperComponent,
    ReactiveFormsModule,
    TranslateModule,
    DatePipe,
    EmptyStateListComponent,
    IconButtonComponent,
    MatCheckbox,
    MatFormField,
    MatIcon,
    MatInput,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSort,
    MatSortHeader,
    MatSuffix,
    FilterOptionWrapperComponent,
    ParameterListComponent,
    RouterLink,
    CustomPaginatorComponent
  ],
  templateUrl: './component-details-parameters.component.html',
  styleUrl: './component-details-parameters.component.scss'
})
export class ComponentDetailsParametersComponent {
  @Input() isReadOnlyMode: boolean = false;
  @Input() component: WsComponentResponseDto | undefined | null = undefined;
  @ViewChild(ParameterListComponent) parameterListComponent?: ParameterListComponent;

  constructor() {
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  sortData($event: Sort) {
    //TODO
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.parameterListComponent ? this.parameterListComponent.canDeactivate() : true;
  }

  protected readonly RouteConstants = RouteConstants;
}
