import {Component, OnDestroy, OnInit} from '@angular/core';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {OverlayComponent} from '../../../shared/components/overlay/overlay.component';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {Subject, takeUntil} from 'rxjs';
import {ProjectDetailsService} from '../../services/project-details.service';
import {WsProjectResponseDto} from '@fertirob/fertirob-api';
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {UiProjectExport} from '../../classes/ui-project-export';

@Component({
  selector: 'app-export-project-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    InputWrapperComponent,
    OverlayComponent,
    ReactiveFormsModule,
    TranslateModule,
    MatFormField,
    MatIcon,
    MatOption,
    MatSelect,
    MatSuffix,
    HeadlineComponent
  ],
  templateUrl: './export-project-overlay.component.html',
  styleUrl: './export-project-overlay.component.scss'
})
export class ExportProjectOverlayComponent implements OnInit, OnDestroy {
  public project: WsProjectResponseDto | undefined = undefined;
  public exportForm: FormGroup;
  public formats: string[] = ['.csv'];
  private _notifier = new Subject<void>();

  constructor(private _fb: FormBuilder,
              public dialogRef: MatDialogRef<ExportProjectOverlayComponent>,
              private _projectDetailsService: ProjectDetailsService
  ) {
    this.exportForm = this._fb.group({
      name: ['', Validators.required],
      format: [this.formats[0], Validators.required]
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  onHideOverlay = (): void => {
    this.dialogRef.close(false);
  }

  onSubmit = (): void => {
    this._projectDetailsService.exportProject(this.project?.id ?? '').then(response => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response);
      link.download = this.exportForm.value.name + '.csv';
      link.click();
      window.URL.revokeObjectURL(link.href);
    })
    this.dialogRef.close(true);
  }
  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
