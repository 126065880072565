<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    <app-headline [title]="'documents.documents' | translate" [hideDivider]="true">
      <input class="hide" #fileSelector type="file" accept=".pdf" multiple (change)="onTriggerImportViaButton($event, fileSelector.files)">
      <input class="hide" #folderSelector type="file" webkitdirectory multiple
             (change)="onTriggerImportViaButton($event, folderSelector.files)">
      <app-button [label]="'select.selectDocs' | translate"
                  [isDisabled]="isEmpty"
                  (click)="toggleSelectionMode(true)"
                  [type]="ButtonType.SECONDARY"></app-button>
      <app-button [label]="'documents.fileUpload' | translate"
                  (click)="selectFolder = false; fileSelector.click()"></app-button>
      <app-button [label]="'documents.folderUpload' | translate"
                  (click)="selectFolder = true; folderSelector.click()"></app-button>
    </app-headline>
    @if (selectionMode) {
      <app-selection-bar
        [selectedAmount]="checkedDocuments.length"
        (onChangeSelectionMode)="toggleSelectionMode(false)"
        (onTriggerDelete)="deleteOverlay()"
        (onTriggerDownload)="onDownloadFile()"
      ></app-selection-bar>
    }
    @if (!(isEmpty) && !(isLoading$ | async)) {
      <div class="main-content">
        <div class="table table-grid" cdkDropListGroup (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)">
          <div class="dropzone" [class.dragover]="isDragged">
            <div class="table-row table-header-row" [class.two-column]="showVersions || showComments"
                 [class.three-column]="!showComments && !showVersions">
              <div class="table-cell">
                <div class="flex-content-wrapper">
                  @if (selectionMode) {
                    <mat-checkbox (change)="onCheckAll($event.checked)"
                                  [checked]="areAllChecked()"
                                  [indeterminate]="areSomeChecked()">
                    </mat-checkbox>
                  }
                  {{ 'default.name' | translate }}
                </div>
              </div>
              @if (!showVersions && !showComments) {
                <div class="table-cell">{{ 'documents.uploaded' | translate }}</div>
              }
              <div class="table-cell">{{ 'default.edit' | translate }}</div>
            </div>
            @if (isDragged) {
              <div
                class="dropzone-files"
                (drop)="onDropFilesAndFolders($event)"
                (dragover)="onDragOverFilesAndFolders($event)">
              </div>
            }
            @for (document of folders; track document.id; let i = $index) {
              <div class="table-row folder-row"
                   [class.two-column]="showVersions || showComments"
                   [class.three-column]="!showVersions && !showComments"
                   cdkDropList
                   cdkDropListSortingDisabled
                   [cdkDropListData]="folders"
                   (cdkDropListDropped)="drop($event, document.id)"
                   [routerLink]="['/', RouteConstants.routeDocuments, RouteConstants.routeNoId, document.id]">
                <div class="table-cell">
                  @if (selectionMode) {
                    <mat-checkbox (change)="onCheckboxCheck($event.checked, document.id)"
                                  [checked]="isChecked(document.id)"
                                  (click)="$event.stopPropagation()"></mat-checkbox>
                  }
                  <div class="flex-content-wrapper">
                    <i
                      class="material-icons">{{ document?.type === DocumentType.PDF ? IconEnum.DOCUMENTS : IconEnum.FOLDER }}</i>
                    {{ document.displayFileName }}
                  </div>
                </div>
                @if (!showVersions && !showComments) {
                  <div class="table-cell">{{ document.uploadedDate | date: 'short' }}</div>
                }
                <div class="table-cell" (click)="$event.stopPropagation()">
                  <div class="flex-content-wrapper">
                    <app-icon-button (click)="onDownloadFile(document)"
                                     [iconName]="IconEnum.DOWNLOAD"
                                     [type]="ButtonType.PLAIN"></app-icon-button>
                    <app-icon-button [iconName]="IconEnum.MORE"
                                     [type]="ButtonType.PLAIN"
                                     [matMenuTriggerFor]=" menu"></app-icon-button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onDelete(document)">
                        <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
                        {{ 'documents.dropdown.delete' | translate }}
                      </button>
                      <button mat-menu-item (click)="onShowVersions(document)">
                        <mat-icon class="material-icons">{{ IconEnum.HISTORY }}</mat-icon>
                        {{ 'documents.dropdown.version' | translate }}
                      </button>
                      <button mat-menu-item (click)="onShowComments(document.id)">
                        <mat-icon class="material-icons">{{ IconEnum.COMMENT }}</mat-icon>
                        {{ 'documents.dropdown.comment' | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            }
            @for (document of files; track document.id; let i = $index) {
              <div cdkDropList
                   cdkDropListSortingDisabled
                   [cdkDropListData]="files">
                <div class="table-row documents"
                     (mousedown)="onDragFile(document)"
                     [class.two-column]="showVersions || showComments"
                     [class.three-column]="!showVersions && !showComments"
                     cdkDrag>
                  <div class="table-cell" cdkDragHandle>
                    @if (selectionMode) {
                      <mat-checkbox (change)="onCheckboxCheck($event.checked, document.id)"
                                    [checked]="isChecked(document.id)"></mat-checkbox>
                    }
                    <div class="flex-content-wrapper">
                      <i
                        class="material-icons">{{ document?.type === DocumentType.PDF ? IconEnum.DOCUMENTS : IconEnum.FOLDER }}</i>
                      {{ document.displayFileName }}
                      <!-- <app-chip [type]="ChipType.NEW_COMMENT"></app-chip>-->
                    </div>
                  </div>
                  @if (!showVersions && !showComments) {
                    <div class="table-cell" cdkDragHandle>{{ document.uploadedDate | date: 'short' }}</div>
                  }
                  <div class="table-cell" cdkDragHandle>
                    <div class="flex-content-wrapper">
                      <app-icon-button (click)="onDownloadFile(document)"
                                       [iconName]="IconEnum.DOWNLOAD"
                                       [type]="ButtonType.PLAIN"></app-icon-button>
                      <app-icon-button [iconName]="IconEnum.MORE"
                                       [type]="ButtonType.PLAIN"
                                       [matMenuTriggerFor]=" menu"></app-icon-button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onDelete(document)">
                          <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
                          {{ 'documents.dropdown.delete' | translate }}
                        </button>
                        <button mat-menu-item (click)="onShowVersions(document)">
                          <mat-icon class="material-icons">{{ IconEnum.HISTORY }}</mat-icon>
                          {{ 'documents.dropdown.version' | translate }}
                        </button>
                        <button mat-menu-item (click)="onShowComments(document.id)">
                          <mat-icon class="material-icons">{{ IconEnum.COMMENT }}</mat-icon>
                          {{ 'documents.dropdown.comment' | translate }}
                        </button>
                        <!--<button mat-menu-item (click)="showUploadOverlay()">
                          <mat-icon class="material-icons">{{ IconEnum.UPLOAD }}</mat-icon>
                          {{ 'documents.dropdown.upload' | translate }}
                        </button>-->
                      </mat-menu>
                    </div>
                  </div>
                  <div *cdkDragPreview class="dragdrop-preview">{{ document.displayFileName }}</div>
                </div>
              </div>
            }
          </div>
        </div>
        @if (showVersions) {
          <app-versions-list (onClose)="onShowVersions()"></app-versions-list>
        }
        @if (showComments) {
          <app-comment-list (onClose)="onShowComments()"></app-comment-list>
        }
      </div>
    }
    @if (!isEmpty) {
      <app-custom-paginator
        (pageChanged)="onChangePage($event)"
        [totalElements]="totalCount"
        [currentPage]="currentPage"
        [pageSize]="pageSize"
      ></app-custom-paginator>
    }
    @if (isEmpty && !(isLoading$ | async)) {
      <app-empty-state-list
        [emptyStateText]="'documents.documentsEmptyState' | translate"></app-empty-state-list>
    }
    @if (isLoading$ | async) {
      <app-spinner></app-spinner>
    }
  </ng-container>
</app-page-layout>
