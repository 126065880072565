import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BreadCrumbNavigationComponent
} from "../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {TabBarComponent} from "../../../shared/components/tab-bar/tab-bar.component";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {PageLayoutComponent} from "../../../shared/components/page-layout/page-layout.component";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  DragAndDropComponent
} from "../../../shared/components/drag-and-drop/drag-and-drop.component";
import {TabBarEnum} from "../../../shared/enums/tab-bar-enum";
import {UiTabBar} from "../../../shared/classes/ui-tab-bar";
import {
  ComponentDetailsGeneralComponent
} from "../component-details-general/component-details-general.component";
import {firstValueFrom, Observable, Subject, takeUntil, tap} from "rxjs";
import {ActivatedRoute, CanDeactivate, Router, RouterLink} from "@angular/router";
import {
  ComponentDetailsParametersComponent
} from "../component-details-parameters/component-details-parameters.component";
import {ComponentDetailsService} from "../../services/component-details.service";
import {StepBarComponent} from "../../../shared/components/step-bar/step-bar.component";
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {UiBreadCrumbItem} from '../../../shared/classes/ui-bread-crumb-item';
import {ModuleDetailsService} from '../../services/module-details.service';
import {
  WsModuleResponseDto,
  WsComponentResponseDto,
} from '@fertirob/fertirob-api';
import {AsyncPipe} from '@angular/common';
import {InfoBannerComponent} from "../../../shared/components/info-banner/info-banner.component";

@Component({
  selector: 'app-page-component-details',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    HeadlineComponent,
    TabBarComponent,
    PageLayoutComponent,
    InputWrapperComponent,
    FormsModule,
    ReactiveFormsModule,
    DragAndDropComponent,
    ComponentDetailsGeneralComponent,
    ComponentDetailsParametersComponent,
    StepBarComponent,
    TranslateModule,
    RouterLink,
    ButtonComponent,
    AsyncPipe,
    InfoBannerComponent
  ],
  templateUrl: './page-component-details.component.html',
  styleUrl: './page-component-details.component.scss'
})
export class PageComponentDetailsComponent implements OnInit, OnDestroy, CanDeactivate<any> {
  @ViewChild(ComponentDetailsGeneralComponent) componentDetailsGeneralComponent?: ComponentDetailsGeneralComponent;
  @ViewChild(ComponentDetailsParametersComponent) componentDetailsParametersComponent?: ComponentDetailsParametersComponent;

  public component$: Observable<WsComponentResponseDto | undefined> = this._componentDetailsService.getComponent();

  public isSubComponent: boolean = false;

  public statesList: UiTabBar[] = [
    {label: TabBarEnum.GENERAL, link: ['../', RouteConstants.routeGeneral]},
    {label: TabBarEnum.PARAMETERS, link: ['../', RouteConstants.routeParameters]}
  ];
  public activeState: UiTabBar = this.statesList[0];
  public breadCrumbItems: UiBreadCrumbItem[] = [];

  private _notifier = new Subject<void>();

  constructor(private _route: ActivatedRoute,
              private _translate: TranslateService,
              private _componentDetailsService: ComponentDetailsService,
              private _moduleDetailsService: ModuleDetailsService
  ) {
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      if (params['idComponent']) {
        this.isSubComponent = true;
        this._componentDetailsService.fetchComponent(params['idComponent']);
      }
    });
    this.setUpBreadCrumb();
  }

  public async setUpBreadCrumb() {
    let translations = await firstValueFrom(this._translate.get(['modules.newPart', 'modules.newAssemblyGroup']));
    if (translations) {
      if (this.isSubComponent) {
        this.component$.pipe(takeUntil(this._notifier)).subscribe(component => {
          this.breadCrumbItems = [];
          if (component) {
            this.breadCrumbItems.push(
              {
                label: component?.name,
              }
            );
            if (component?.parentComponent) {
              this.addBreadCrumbItem(component?.parentComponent);
            }
            this.breadCrumbItems.push({
              label: component?.module?.name,
              route: ['/', RouteConstants.routeModules, component?.module?.id ?? '']
            })
            this.breadCrumbItems.reverse();
          }
        });
      }
    }
  }

  public addBreadCrumbItem(component: WsComponentResponseDto): void {
    this.breadCrumbItems.push(
      {
        label: component.name,
        route: ['/', RouteConstants.routeModules, RouteConstants.routeNoId, component.id ?? '']
      }
    )
    if (component.parentComponent) {
      this.addBreadCrumbItem(component.parentComponent);
    }
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  get isGeneralTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.GENERAL
  }

  get isParametersTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.PARAMETERS
  }

  public onChangeActiveState(activeState: UiTabBar): void {
    this.activeState = activeState;
  }

  canDeactivate(): Observable<boolean> | boolean {
    switch (this.activeState?.label) {
      case TabBarEnum.GENERAL:
        return this.componentDetailsGeneralComponent ? this.componentDetailsGeneralComponent.canDeactivate() : true;
      case TabBarEnum.PARAMETERS:
        return this.componentDetailsParametersComponent ? this.componentDetailsParametersComponent.canDeactivate() : true;
      default:
        return true;
    }
  }

  protected readonly RouteConstants = RouteConstants;
  protected readonly ButtonType = ButtonType;
}
