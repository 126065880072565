import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {RouteConstants} from '../constants/route-constants';

@Injectable({
  providedIn: 'root'
})
export class UrlIdCheckGuardService {

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    let uuidPattern = new RegExp('^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$');

    let idToCheck = '';
    if (route.params['id']) {
      idToCheck = route.params['id'];
    }
    if (route.params['idModule']) {
      idToCheck = route.params['idModule'];
    }
    if (route.params['idComponent']) {
      idToCheck = route.params['idComponent'];
    }

    let isId = (idToCheck === RouteConstants.routeNoId) || uuidPattern.test(idToCheck);
    if (!isId) {
      this.router.navigate(['404'], {skipLocationChange: true});
    }

    return true;
  }
}

export const CanActivateUrlIdCheckGuardService: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(UrlIdCheckGuardService).canActivate(next, state);
}
