@if (hasParameters(component) && hasRules()) {
  <div class="table-row highlight-row two-column">
    <div class="table-cell">
      <div class="offset-element"></div>
      <app-icon-button (click)="selectComponent()"
                       [iconName]="isSelected ? IconEnum.ARROW_UP : IconEnum.ARROW_DOWN"
                       [type]="ButtonType.PLAIN"></app-icon-button>
      {{ component?.name }}
      <app-chip [type]="isComponentComplete ? ChipType.COMPLETE : ChipType.IN_COMPLETE" [counter]="amountComplete"></app-chip>
    </div>
    <div class="table-cell">
      @if (isAssembly(component?.type) || hasFiles()) {
        <button class="text-link" (click)="onShowComponentDetails()">{{ 'default.details' | translate }}</button>
      }
    </div>
  </div>
}
@if (isSelected) {
  <div [formGroup]="parameterForm">
    <div formArrayName="parameters">
      @for (parameter of nonRuleParameters(); track parameter.id; let index = $index) {
        <div class="table-row four-column-project" [formGroupName]="index">
          <div class="table-cell">
            <div class="offset-element-rule"></div>
            {{ parameter.name }}
          </div>
          <div class="table-cell">
            {{ parameter.description }}
          </div>
          <div class="table-cell align-top padding">
            <div class="input-wrapper">
              <input type="number" id="{{parameter.id}}" formControlName="value"
                     [placeholder]="parameter.value ? parameter.value + ('default.default' | translate) : ('default.value' | translate)">
              @if (parameter.value) {
                <p class="hint">{{ 'projects.projectInputDefaultHint' | translate }}</p>
              }
            </div>
            @if (parameter.value) {
              <app-icon-button
                [type]="ButtonType.PRIMARY"
                [iconName]="IconEnum.RESET"
                [isDisabled]="getParameterValue(index) === parameter.value"
                (click)="onResetValue(index, parameter.value)"
              ></app-icon-button>
            }
          </div>
          <div class="table-cell">
            {{ parameter.updatedDate | date: 'short' }}
          </div>
        </div>
      }
    </div>
  </div>
}
@for (component of component?.subComponents; track component) {
  @if (hasParameters(component) || isAssembly(component.type)) {
    <app-component-table-row-in-project [component]="component" [projectParameters]="projectParameters"
                                        (onParameterValueChange)="parameterValueChange($event)"
                                        (onFormDirty)="emitDirty($event)"
                                        (onFormChange)="emitChange($event)"></app-component-table-row-in-project>
  }
}
