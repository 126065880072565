<div class="table-row highlight-row" [class.locked-cell]="module?.readonly">
  <div class="table-cell">
    <app-icon-button (click)="selectModule()"
                     [iconName]="isSelected ? IconEnum.ARROW_UP : IconEnum.ARROW_DOWN"
                     [type]="ButtonType.PLAIN"></app-icon-button>
    <a [routerLink]="[module?.id]">{{ module?.name }}</a>
    @if (module?.readonly) {
      <i class="material-icons info-trigger-button"
         [matMenuTriggerFor]="info">{{ IconEnum.LOCK }}</i>
    }

    <mat-menu #info=matMenu>
      <p
        class="custom-menu-item info-headline">{{ 'modules.moduleUsedIn' | translate }}:</p>
      @if (module && module.projects && module.projects.length > 0) {
        @for (project of module.projects; track project.id) {
          <button mat-menu-item [routerLink]="['/', RouteConstants.routeProjects ,project.id]">{{ project.name }}</button>
        }
      }
    </mat-menu>
  </div>
  <div class="table-cell" [routerLink]="[module?.id]">
    {{ module?.updatedDate | date: 'short' }}
  </div>
  <div class="table-cell">
    <div class="flex-content-wrapper">
      <app-icon-button [iconName]="IconEnum.DOWNLOAD" [type]="ButtonType.PLAIN"></app-icon-button>
      <app-icon-button [iconName]="IconEnum.MORE" [type]="ButtonType.PLAIN"
                       [matMenuTriggerFor]="menu"></app-icon-button>

      <mat-menu #menu=matMenu>
        <button mat-menu-item>
          <mat-icon class="material-icons">{{ IconEnum.FILE_COPY }}</mat-icon>
          {{ 'default.copy' | translate }}
        </button>
        <button mat-menu-item [disabled]="module?.readonly" (click)="deleteOverlay()">
          <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
          {{ 'default.delete' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
@if (isSelected) {
  <div class="table-row">
    <div class="table-cell" [routerLink]="[module?.id, RouteConstants.routeRules]">
      <div class="offset-element"></div>
      <span class="material-icons show-more-icon">{{ IconEnum.RULES }}</span>
      {{ 'default.rules' | translate }}
    </div>
    <div class="table-cell"
         [routerLink]="[module?.id, RouteConstants.routeRules]">{{ module?.updatedDate | date: 'short' }}
    </div>
    <div class="table-cell">
      @if (hasRules) {
        <div class="flex-content-wrapper">
          <app-icon-button [iconName]="IconEnum.DOWNLOAD"
                           [type]="ButtonType.PLAIN"></app-icon-button>
        </div>
      }
    </div>
  </div>
  <div class="table-row">
    <div class="table-cell" [routerLink]="[module?.id, RouteConstants.routeParameters]">
      <div class="offset-element"></div>
      <img class="icon" src="/assets/img/icons/ic_parameter.svg" alt="Parameter">
      {{ 'default.parameter' | translate }}
    </div>
    <div class="table-cell"
         [routerLink]="[module?.id, RouteConstants.routeParameters]">{{ module?.updatedDate | date: 'short' }}
    </div>
    <div class="table-cell">
      @if (hasParameters) {
        <div class="flex-content-wrapper">
          <app-icon-button [iconName]="IconEnum.DOWNLOAD"
                           [type]="ButtonType.PLAIN"></app-icon-button>
        </div>
      }
    </div>
  </div>
  @for (component of module?.components; track component) {
    @if (isAssembly(component.type)) {
      <app-assembly-table-row [assembly]="component" [offset]="0" [enableDelete]="enableDelete"
                              [hideDate]="hideDate"></app-assembly-table-row>
    } @else {
      <app-part-table-row [part]="component" [offset]="0" [enableDelete]="enableDelete"
                          [hideDate]="hideDate"></app-part-table-row>
    }
  }
}

