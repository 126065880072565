import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {StatisticsApiService, WsProjectResponseDto, WsStatisticsResponseDto} from '@fertirob/fertirob-api';
import {UrlConstants} from '../../shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _dashboardInfos: BehaviorSubject<WsStatisticsResponseDto | undefined> = new BehaviorSubject<WsStatisticsResponseDto | undefined>(undefined);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _statisticsApiService: StatisticsApiService) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  public getDashboardInfos = (): Observable<WsStatisticsResponseDto | undefined> => {
    this.fetchDashboardInfos();
    return this._dashboardInfos.asObservable();
  }

  public fetchDashboardInfos = async (): Promise<void> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._statisticsApiService.getStatistics('response'));
      if (result.body) {
        this._dashboardInfos.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }
  }
}
