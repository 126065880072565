<app-page-layout [noButtons]="true">
  <ng-container layout-main>
    <div class="dashboard-tile-wrapper">
      @for (item of dashboardItems; track item.headline) {
        <app-dashboard-item
          [dashboardItem]="item"
          [class.half]="!item.subDashboardItems"
        ></app-dashboard-item>
      }
    </div>
  </ng-container>
</app-page-layout>
