<nav class="navigation-module">
  <div class="top-wrapper">
    <div class="logo-wrapper">
      <img class="logo" src="/assets/img/fertirob_logo_color_rgb.svg"
           alt="{{ 'navigation.logo' | translate}}"
           title="{{ 'navigation.logo' | translate}}">
    </div>
    @for (item of navItems; track item) {
      <app-navigation-item [label]="item.label" [route]="item.route" [iconName]="item.icon"
                           [subItems]="item.subItems"></app-navigation-item>
    }
  </div>
  <!--<div class="bottom-wrapper">
    <app-navigation-item (click)="onLogOut()"
                         [iconName]="IconEnum.LOG_OUT"
                         [label]=" 'navigation.logOut'"
    ></app-navigation-item>
  </div>-->
</nav>
