import {Component, Input} from '@angular/core';
import {DatePipe} from "@angular/common";
import {IconEnum} from "../../enums/icon-enum";
import {TranslateModule} from "@ngx-translate/core";
import {WsDocumentLogResponseDto, WsDocumentLogResponseDtoActionEnum, WsDocumentResponseDto, DocumentType} from '@fertirob/fertirob-api';
import {FileResourcesService} from '../../services/file-resources.service';

@Component({
  selector: 'app-version',
  standalone: true,
  imports: [
    DatePipe,
    TranslateModule
  ],
  templateUrl: './version.component.html',
  styleUrl: './version.component.scss'
})
export class VersionComponent {
  @Input() version: WsDocumentLogResponseDto | undefined = undefined;
  @Input() changeLogMsg?: string = "";
  @Input() noLine?: boolean = false;

  constructor(private _fileResourcesService: FileResourcesService) {
  }

  public downloadFile() {
    if (this.version && this.version.document) {
      let versionDocument: WsDocumentResponseDto = this.version.document;
      this._fileResourcesService.downloadFile(versionDocument.id).then((file) => {
        const blob = new Blob([file], {type: versionDocument.type === DocumentType.PDF ? file.type : 'application/zip'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = versionDocument.displayFileName ?? 'file';
        link.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }

  protected readonly IconEnum = IconEnum;
  protected readonly WsDocumentLogResponseDtoActionEnum = WsDocumentLogResponseDtoActionEnum;
}
