import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(date: Date | string): string {
    const now = new Date();
    const givenDate = new Date(date);
    const diffInMs = now.getTime() - givenDate.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays >= 7) {
      return givenDate.toLocaleDateString();
    } else if (diffInDays > 0) {
      return `Vor ${diffInDays} Tagen`;
    } else if (diffInHours > 0) {
      return `Vor ${diffInHours} Stunden`;
    } else if (diffInMinutes > 0) {
      return `Vor ${diffInMinutes} Minuten`;
    } else {
      return 'Gerade eben';
    }
  }
}
