import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {
  ProjectsApiService,
  WsProjectUpdateRequestDto,
  WsProjectResponseDto,
  WsParameterResponseDto, WsModuleResponseDto
} from '@fertirob/fertirob-api';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {FeedbackService} from '../../shared/services/feedback.service';
import {HelperService} from '../../shared/services/helper.service';
import {ProjectListService} from './project-list.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailsService {

  private _project: BehaviorSubject<WsProjectResponseDto | undefined> = new BehaviorSubject<WsProjectResponseDto | undefined>(undefined);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _projectApiService: ProjectsApiService,
    private _projectListService: ProjectListService,
    private _helperService: HelperService,
    private _feedbackService: FeedbackService
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  public getProject = (): Observable<WsProjectResponseDto | undefined> => {
    return this._project.asObservable();
  }

  public fetchProject = async (id?: string): Promise<void> => {
    this._loading.next(true);
    if (id === this._project.getValue()?.id) {
      this._loading.next(false);
      return
    }
    if (!id) {
      id = this._project.getValue()?.id ?? '';
    }
    this._project.next(undefined);
    try {
      const result = await firstValueFrom(this._projectApiService.getProject(id))

      if (result) {
        this._project.next(result)
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err
    }
  }

  /*public exportProject = (projectId: string, name: string, format: string): Observable<any> => {
    //TODO Call Service
    return new Observable();
  } */

  public exportProject = async (projectId: string): Promise<Blob> => {
    try {
      const result = await firstValueFrom(this._projectApiService.exportProject(projectId))
      this._feedbackService.setFeedback('snackbar.projectExport.success', FeedbackType.SUCCESS);
      return Promise.resolve(result);
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }

  public updateProject = async (projectId: string, updateProject: WsProjectUpdateRequestDto): Promise<string> => {
    try {
      const result = await firstValueFrom(this._projectApiService.updateProject(projectId, updateProject))
      this._feedbackService.setFeedback('snackbar.projectUpdate.success', FeedbackType.SUCCESS);
      await this.fetchProject();
      return Promise.resolve(result.id ?? '');
    } catch (err: any) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err.status));
      throw err;
    }
  }

  public deleteProject = async (id: string): Promise<void> => {
    try {
      await firstValueFrom(this._projectApiService.deleteProject(id))
      this._feedbackService.setFeedback('snackbar.projectDelete.success', FeedbackType.SUCCESS);
      await this._projectListService.fetchProjectList();
    } catch (err: any) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err.status));
      throw err;
    }
  }

  public hasParameterRule = (parameter: WsParameterResponseDto, module: WsModuleResponseDto): boolean => {
    if (module.rules) {
      return module.rules.some(item => item.parameterName === parameter.name);
    }

    return false;
  }
}
