<div class="comment-list">
  <app-headline [title]="'default.comments' | translate">
    <app-icon-button [iconName]="IconEnum.CLOSE" [type]="ButtonType.PLAIN"
                     (click)="onCloseComments()"></app-icon-button>
  </app-headline>
  <div class="comment-content">
    @if (!(noComments | async) && !(isLoading$ | async)) {
      @for (comment of (comments$ | async); let i = $index; track comment) {
        <app-comment [comment]="comment"
                     (triggerSelected)="setSelectedCommentId($event)"
                     [isSelectedComment]="selectedCommentId"></app-comment>
      }
    }
    @if ((noComments | async) && !(isLoading$ | async)) {
      <app-empty-state-list [emptyStateText]="'documents.commentsEmptyState' | translate"></app-empty-state-list>
    }
    @if (isLoading$ | async) {
      <app-spinner></app-spinner>
    }
  </div>
  <div class="input-wrapper send main-send">
    <div class="textarea-wrapper">
               <textarea matInput class="comment-textarea" [(ngModel)]="newComment"
                         [placeholder]="'comments.newComment' | translate"></textarea>
      <button class="send-button" matSuffix [disabled]="!newComment" (click)="onSendComment()">
        <mat-icon class="material-icons">{{ IconEnum.SEND }}</mat-icon>
      </button>
    </div>
  </div>
</div>
