import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {
  DocumentApiService,
  DocumentLogsApiService,
  DocumentType,
  WsDocumentLogResponseDto,
  WsDocumentResponseDto
} from '@fertirob/fertirob-api';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {FeedbackService} from '../../shared/services/feedback.service';
import {HelperService} from '../../shared/services/helper.service';
import {UiUploadNewVersion} from '../classes/ui-upload-new-version';

@Injectable({
  providedIn: 'root'
})
export class VersionDataService {
  private _versions: BehaviorSubject<WsDocumentLogResponseDto[]> = new BehaviorSubject<WsDocumentLogResponseDto[]>([]);
  private _document: BehaviorSubject<WsDocumentResponseDto | undefined> = new BehaviorSubject<WsDocumentResponseDto | undefined>(undefined);
  private _projectId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private _documentLogsApiService: DocumentLogsApiService,
    private _documentApiService: DocumentApiService,
    private _feedbackService: FeedbackService,
    private _helperService: HelperService,
  ) {
  }

  public get getSelectedDocument(): WsDocumentResponseDto | undefined {
    return this._document.getValue();
  }

  public setSelectedDocument = (document: WsDocumentResponseDto): void => {
    this._document.next(document)
    this.fetchVersions();
  }

  public get getProjectId(): string {
    return this._projectId.getValue();
  }

  public setProjectId = (id: string): void => {
    this._projectId.next(id)
  }

  public getVersions = (): Observable<WsDocumentLogResponseDto[]> => {
    return this._versions.asObservable();
  }

  private fetchVersions = async (): Promise<void> => {
    try {
      const result = await firstValueFrom(this._documentLogsApiService.getDocumentLogs(this.getSelectedDocument?.id??'', 'response'));
      if (result.body) {
        this._versions.next(result.body);
      }
    } catch (err) {
      throw err;
    }
  }

  public uploadNewVersion = async (newVersion?: UiUploadNewVersion): Promise<string> => {
    if (newVersion?.files && newVersion.files[0]) {
      return this.updateDocument(newVersion?.files[0])
    }

    return Promise.resolve("")
  }

  public updateDocument = async (document?: Blob): Promise<string> => {
    try {
      const result = await firstValueFrom(this._documentApiService.createOrUpdateDocument(this.getSelectedDocument?.parentDocument?.id??this.getProjectId??'', this.getSelectedDocument?.type??DocumentType.PDF, this.getSelectedDocument?.displayFileName??'', this.getSelectedDocument?.ownerEmail??'', document));
      this._feedbackService.setFeedback('snackbar.documentCreate.success', FeedbackType.SUCCESS);
      return Promise.resolve(result.id ?? '');
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }
}
