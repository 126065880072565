import {Component, EventEmitter, Output} from '@angular/core';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {AsyncPipe} from '@angular/common';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {VersionComponent} from '../../../shared/components/version/version.component';
import {map, Observable} from 'rxjs';
import {WsDocumentLogResponseDto} from '@fertirob/fertirob-api';
import {ActivatedRoute} from '@angular/router';
import {DocumentListService} from '../../services/document-list.service';
import {VersionDataService} from '../../services/version-data.service';
import {CommentListService} from '../../services/comment-list.service';
import {ProjectDetailsService} from '../../../projects/services/project-details.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-versions-list',
  standalone: true,
  imports: [
    AsyncPipe,
    EmptyStateListComponent,
    HeadlineComponent,
    IconButtonComponent,
    TranslatePipe,
    VersionComponent
  ],
  templateUrl: './versions-list.component.html',
  styleUrl: './versions-list.component.scss'
})
export class VersionsListComponent {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public versions$: Observable<WsDocumentLogResponseDto[]> = this._versionDataService.getVersions();

  constructor(
    private _versionDataService: VersionDataService,
  ) {
  }

  get noVersions(): Observable<boolean> {
    return this.versions$.pipe(
      map(versions => versions?.length === 0)
    );
  }

  onCloseVersions(): void {
    this.onClose.emit(true);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
