<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    <app-headline [title]="'modules.modulesOverview' | translate" [hideDivider]="true">
      <!--<input class="hide" #file type="file" accept=".csv" (change)="onTriggerImportViaButton(file.files)">
      <app-button [type]="ButtonType.SECONDARY" [label]="'default.import' | translate"
                  (click)="file.click()"></app-button>-->
      <app-button [type]="ButtonType.PRIMARY" [label]="'default.new' | translate"
                  (click)="onShowAddModuleOverlay()"></app-button>
    </app-headline>
    @if (!(isEmpty | async) && !(isLoading$ | async)) {
      <div class="table table-grid">
        <div class="table-row table-header-row">
          <div class="table-cell">{{ 'default.name' | translate }}</div>
          <div class="table-cell">{{ 'default.updatedDate' | translate }}</div>
          <div class="table-cell">{{ 'default.edit' | translate }}</div>
        </div>
        @for (module of (modules$ | async); track module.id) {
          <app-module-table-row [module]="module" [enableDelete]="true"></app-module-table-row>
        }
      </div>
    }
    @if (!(isEmpty | async)) {
      <app-custom-paginator
        (pageChanged)="onChangePage($event)"
        [totalElements]="totalCount"
        [currentPage]="currentPage"
        [pageSize]="pageSize"
      ></app-custom-paginator>
    }
    @if ((isEmpty | async) && !(isLoading$ | async)) {
      <app-empty-state-list [emptyStateText]="'modules.modulesEmptyState' | translate"></app-empty-state-list>
    }
    @if (isLoading$ | async) {
      <app-spinner></app-spinner>
    }
  </ng-container>
</app-page-layout>
