import {Component} from '@angular/core';
import {OverlayComponent} from "../../../shared/components/overlay/overlay.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {MatDialogRef} from "@angular/material/dialog";
import {FeedbackService} from "../../../shared/services/feedback.service";
import {FeedbackType} from "../../../shared/enums/feedback-type";

@Component({
  selector: 'app-delete-document-permanently-overlay',
  standalone: true,
  imports: [
    OverlayComponent,
    HeadlineComponent,
    IconButtonComponent,
    TranslateModule,
    ButtonComponent
  ],
  templateUrl: './delete-document-permanently-overlay.component.html',
  styleUrl: './delete-document-permanently-overlay.component.scss'
})
export class DeleteDocumentPermanentlyOverlayComponent {
  public fileName: string = ""

  constructor(
    private _dialogRef: MatDialogRef<DeleteDocumentPermanentlyOverlayComponent>,
  ) {
  }


  cancel(): void {
    this._dialogRef.close(false);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
  protected readonly document = document;

  onSubmit = (): void => {
    this._dialogRef.close(true);
  }
}
