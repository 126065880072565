import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {
  DocumentCommentsApiService,
  WsDocumentCommentRequestDto,
  WsDocumentCommentResponseDto,
} from '@fertirob/fertirob-api';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {HelperService} from '../../shared/services/helper.service';
import {FeedbackService} from '../../shared/services/feedback.service';

@Injectable({
  providedIn: 'root'
})
export class CommentListService {

  private _comments: BehaviorSubject<WsDocumentCommentResponseDto[]> = new BehaviorSubject<WsDocumentCommentResponseDto[]>([]);
  private _documentId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _documentCommentsApiService: DocumentCommentsApiService,
    private _helperService: HelperService,
    private _feedbackService: FeedbackService
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  public get getDocumentId(): string {
    return this._documentId.getValue();
  }

  public setDocumentId = (id: string): void => {
    this._documentId.next(id)
    this.fetchComments();
  }

  public getComments = (): Observable<WsDocumentCommentResponseDto[]> => {
    return this._comments.asObservable();
  }

  private fetchComments = async (): Promise<void> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._documentCommentsApiService.getDocumentComments(this.getDocumentId,'response'));
      if (result.body) {
        this._comments.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }
  }

  public createComment = async (comment: WsDocumentCommentRequestDto): Promise<string> => {
    try {
      const result = await firstValueFrom(this._documentCommentsApiService.createDocumentComment(this.getDocumentId, comment))
      this._feedbackService.setFeedback('snackbar.commentCreate.success', FeedbackType.SUCCESS);
      await this.fetchComments();
      return Promise.resolve(result.id ?? '');
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }
}
