import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  BreadCrumbNavigationComponent
} from "../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component";
import {AsyncPipe, DatePipe} from "@angular/common";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {PageLayoutComponent} from "../../../shared/components/page-layout/page-layout.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {DocumentListService} from "../../services/document-list.service";
import {firstValueFrom, Observable, Subject, takeUntil} from "rxjs";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {ProjectDetailsService} from "../../../projects/services/project-details.service";
import {UiBreadCrumbItem} from "../../../shared/classes/ui-bread-crumb-item";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MatCheckbox} from "@angular/material/checkbox";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {MatBadge} from "@angular/material/badge";
import {
  UploadNewVersionOverlayComponent
} from "../upload-new-version-overlay/upload-new-version-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {VersionComponent} from "../../../shared/components/version/version.component";
import {TabBarComponent} from "../../../shared/components/tab-bar/tab-bar.component";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {VersionDataService} from "../../services/version-data.service";
import {ChipComponent} from "../../../shared/components/chip/chip.component";
import {ChipType} from "../../../shared/enums/chip-enum";
import {
  SelectionBarComponent
} from '../../../shared/components/selection-bar/selection-bar.component';
import {
  DocumentType, WsComponentResponseDto,
  WsDocumentResponseDto,
  WsDocumentUpdateRequestDto,
  WsProjectResponseDto
} from '@fertirob/fertirob-api';
import {CommentListService} from "../../services/comment-list.service";
import {CommentComponent} from "../../../shared/components/comment/comment.component";
import {MatFormField, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  CustomPaginatorComponent
} from "../../../shared/components/custom-paginator/custom-paginator.component";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDragPreview,
  CdkDropList,
  CdkDropListGroup
} from "@angular/cdk/drag-drop";
import {DocumentTableRowComponent} from "../document-table-row/document-table-row.component";
import {
  DeleteOverlayComponent
} from '../../../shared/components/delete-overlay/delete-overlay.component';
import {CommentListComponent} from '../comment-list/comment-list.component';
import {VersionsListComponent} from '../versions-list/versions-list.component';
import {FileResourcesService} from '../../../shared/services/file-resources.service';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';
import {UiUploadNewVersion} from '../../classes/ui-upload-new-version';
import {FeedbackType} from "../../../shared/enums/feedback-type";
import {FeedbackService} from "../../../shared/services/feedback.service";

@Component({
  selector: 'app-page-project-document-list',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    DatePipe,
    HeadlineComponent,
    PageLayoutComponent,
    TranslateModule,
    RouterLink,
    IconButtonComponent,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatIcon,
    MatCheckbox,
    ButtonComponent,
    MatBadge,
    VersionComponent,
    TabBarComponent,
    MatTab,
    MatTabGroup,
    ChipComponent,
    SelectionBarComponent,
    CommentComponent,
    MatFormField,
    MatInput,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    CustomPaginatorComponent,
    AsyncPipe,
    EmptyStateListComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPreview,
    CdkDragPlaceholder,
    DocumentTableRowComponent,
    CdkDropListGroup,
    CommentListComponent,
    VersionsListComponent,
    SpinnerComponent,
  ],
  templateUrl: './page-project-document-list.component.html',
  styleUrl: './page-project-document-list.component.scss'
})
export class PageProjectDocumentListComponent implements OnInit, OnDestroy {
  public files: WsDocumentResponseDto[] = [];
  public folders: WsDocumentResponseDto[] = [];
  public project$: Observable<WsProjectResponseDto | undefined> = this._projectDetailsService.getProject();
  public isLoading$: Observable<boolean> = this._documentListService.isLoading;

  public movedFile?: WsDocumentResponseDto = undefined;

  public breadCrumbItems: UiBreadCrumbItem[] = [];
  public selectionMode: boolean = false;
  public checkedDocuments: string[] = [];
  public showVersions: boolean = false;
  public showComments: boolean = false;
  public isDragged: boolean = false;
  private _notifier = new Subject<void>();

  private _dragCounter = 0;
  public selectFolder = false;

  constructor(
    private _route: ActivatedRoute,
    private _documentListService: DocumentListService,
    private _versionDataService: VersionDataService,
    private _commentListService: CommentListService,
    private _projectDetailsService: ProjectDetailsService,
    private _fileResourcesService: FileResourcesService,
    private _translate: TranslateService,
    private _dialog: MatDialog,
    private _feedbackService: FeedbackService
  ) {
  }

  get pageSize(): number {
    return this._documentListService.getPageSize;
  }

  get currentPage(): number {
    return this._documentListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._documentListService.getTotalCount;
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      if (params['id']) {
        if (params['id'] !== RouteConstants.routeNoId) {
          this._versionDataService.setProjectId(params['id'])
          this._documentListService.setProjectId(params['id'])
          this._projectDetailsService.fetchProject(params['id']);
        } else {
          if (params['idDocument']) {
            this._documentListService.setParentDocumentId(params['idDocument']);
          }
        }
      }
    });
    this._documentListService.getDocumentList().pipe(takeUntil(this._notifier)).subscribe((result) => {
      this.files = []
      this.folders = []
      if (result && result.length > 0) {
        result.forEach((element: WsDocumentResponseDto) => {
          if (element.type === DocumentType.PDF) {
            this.files.push(element);
          } else {
            this.folders.push(element);
          }
        })
        this.setUpBreadCrumb(result);
      } else {
        this.project$.pipe(takeUntil(this._notifier)).subscribe(project => {
          this._translate.get(['documents.documents']).subscribe((translations => {
            this.breadCrumbItems = [
              {
                label: translations['documents.documents'],
                route: ['/', RouteConstants.routeDocuments]
              },
              {label: project?.name},
            ];
          }))
        })

      }
    });
  }

  public async setUpBreadCrumb(result: WsDocumentResponseDto[]) {
    this._translate.get(['documents.documents']).subscribe((translations => {
      this.breadCrumbItems = [];
      if (result[0]?.parentDocument) {
        this.addBreadCrumbItem(result[0]);
      }

      this.breadCrumbItems.push({
        label: result[0]?.project.name,
        route: ['/', RouteConstants.routeDocuments, result[0]?.project.id ?? '']
      })
      this.breadCrumbItems.push({
        label: translations['documents.documents'],
        route: ['/', RouteConstants.routeDocuments]
      })
      this.breadCrumbItems.reverse();
    }))
  }

  public addBreadCrumbItem(document: WsDocumentResponseDto): void {
    this.breadCrumbItems.push(
      {
        label: document?.parentDocument?.displayFileName ?? '',
        route: ['/', RouteConstants.routeDocuments, RouteConstants.routeNoId, document?.parentDocument?.id ?? '']
      }
    )
    if (document?.parentDocument?.parentDocument) {
      this.addBreadCrumbItem(document?.parentDocument);
    }
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  public onCheckboxCheck(checked: boolean, documentId: string | undefined): void {
    if (!documentId) return;
    if (checked) {
      this.checkedDocuments.push(documentId);
    } else {
      this.checkedDocuments = this.checkedDocuments.filter(doc => doc !== documentId);
    }

    console.log('Updated checkedDocuments:', this.checkedDocuments);
  }

  public onCheckAll(checked: boolean): void {
    this.checkedDocuments = [];
    if (checked) {
      this.files?.forEach((param) => {
        if (param.id) {
          this.checkedDocuments.push(param.id);
        }
      })
      this.folders?.forEach((param) => {
        if (param.id) {
          this.checkedDocuments.push(param.id)
        }
      })
    }
  }

  public isChecked(documentId?: string): boolean {
    if (!documentId) return false;
    return this.checkedDocuments.includes(documentId);
  }

  public areAllChecked(): boolean {
    return this.checkedDocuments.length === this.files?.length + this.folders?.length;
  }

  public areSomeChecked(): boolean {
    return this.checkedDocuments.length > 0 && !this.areAllChecked();
  }

  public toggleSelectionMode(mode: boolean) {
    this.selectionMode = mode;
    if (!this.selectionMode) {
      this.checkedDocuments = [];
    }
  }

  public onDelete(document: WsDocumentResponseDto): void {
    this.deleteOverlay(document)
  }

  public deleteOverlay(value?: WsDocumentResponseDto): void {
    const dialogRef = this._dialog.open(DeleteOverlayComponent);
    const filesAndDocs = [...this.folders, ...this.files];

    if (value) {
      dialogRef.componentInstance.values = value!.displayFileName ?? '';
    } else {
      dialogRef.componentInstance.values = filesAndDocs.filter(document => this.checkedDocuments.includes(document.id ?? '')).map(document => document.displayFileName).join(', ');
    }

    dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
      if (confirmed) {
        if (value) {
          await this._documentListService.markForDeleteSingleDocument(value.id ?? '');
        } else {
          const documentIds = filesAndDocs
            ?.filter(document => this.checkedDocuments.includes(document.id ?? ''))
            .map(document => document.id);
          await this._documentListService.markForDeleteMultiDocuments(documentIds);
        }
        this.toggleSelectionMode(false)
      }
    });

  }

  protected readonly RouteConstants = RouteConstants;
  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;

  public showUploadOverlay(): void {
    const dialogRef = this._dialog.open(UploadNewVersionOverlayComponent);
    dialogRef.afterClosed().subscribe((result: UiUploadNewVersion) => {
      if (result) {
        this._versionDataService.uploadNewVersion(result)
      }
    });
  }

  public onShowVersions(document?: WsDocumentResponseDto) {
    if (document) {
      this._versionDataService.setSelectedDocument(document)
    }
    this.showVersions = !this.showVersions;
    this.showComments = false;
  }

  public onShowComments(documentId?: string) {
    if (documentId) {
      this._commentListService.setDocumentId(documentId)
    }
    this.showComments = !this.showComments;
    this.showVersions = false;
  }

  public onChangePage(page: number) {
    this._documentListService.setCurrentPage(page);
  }

  get isEmpty(): boolean {
    return this.files.length === 0 && this.folders.length === 0;
  }

  public drop(event: CdkDragDrop<WsDocumentResponseDto[], any>, folderId: string | undefined) {
    if (this.movedFile && folderId) {
      let movedDocument: WsDocumentUpdateRequestDto = {
        parentId: folderId,
        ownerEmail: this.movedFile.ownerEmail,
        displayFileName: this.movedFile.displayFileName ?? '',
        markedForRemoval: this.movedFile.markedForRemoval
      }
      this._documentListService.updateDocument(this.movedFile.id, movedDocument)
    }
  }

  public onDragEnter(event: DragEvent) {
    event.preventDefault();
    this._dragCounter++;
    this.isDragged = true;
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    this._dragCounter--;
    if (this._dragCounter === 0) {
      this.isDragged = false;
    }
  }

  public onDownloadFile(document?: WsDocumentResponseDto) {
    if (!document) {
      this.files.filter(document => this.checkedDocuments.includes(document.id ?? '')).map(document => {
        this.downloadFile(document);
      });
    } else {
      this.downloadFile(document);
    }
  }

  private downloadFile(documentDownload: WsDocumentResponseDto) {
    this._fileResourcesService.downloadFile(documentDownload.id).then((file) => {
      const blob = new Blob([file], {type: documentDownload.type === DocumentType.PDF ? file.type : 'application/zip'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = documentDownload.displayFileName ?? 'file';
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }

  public async onTriggerImportViaButton(event: any, files: FileList | null) {
    if (!files) return;

    for (let i = 0; i < files?.length; i++) {
      if (this._documentListService.getProjectId || this._documentListService.getParentDocumentId) {
        const filePath = (files[i] as any).webkitRelativePath || files[i].name
        const directories = filePath.split('/')
        directories.pop()

        let parentId = (this._documentListService.getParentDocumentId) ? this._documentListService.getParentDocumentId : this._documentListService.getProjectId!;
        for (let i = 0; i < directories.length; i++) {
          parentId = await this.createDocumentFolder(parentId, directories[i]);
        }

        await this.createDocument(parentId, files[i])
      }
    }

    await this._documentListService.fetchDocumentList();
    event.target.value = '';
  }

  public onDropFilesAndFolders(event: DragEvent): void {
    event.preventDefault();
    this.isDragged = false
    this._dragCounter = 0;

    const items = event.dataTransfer?.items;
    if (!items) return;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item) {
        this.traverseFileTree(item);
      }
    }
  }

  private traverseFileTree(entry: any, parentDirectory?: string): void {
    if (!parentDirectory) {
      parentDirectory = (this._documentListService.getParentDocumentId) ? this._documentListService.getParentDocumentId : this._documentListService.getProjectId;
    }

    if (entry.isFile) {
      entry.file(async (file: File) => {
        await this.createDocument(parentDirectory!, file)
        await this._documentListService.fetchDocumentList();
      });
    } else if (entry.isDirectory) {
      const reader = entry.createReader();
      reader.readEntries(async (entries: any[]) => {
        parentDirectory = await this.createDocumentFolder(parentDirectory!, entry.name);
        entries.forEach(e => this.traverseFileTree(e, parentDirectory));
      });
    }
  }

  private createDocument = async (parentId: string, file: File): Promise<string | null> => {
    if (file.type === 'application/pdf') {
      return this._documentListService.createDocument(parentId, DocumentType.PDF, file.name, '', file);
    } else {
      this._feedbackService.setFeedback('snackbar.fileType.failure', FeedbackType.ERROR, file.name);
    }

    return Promise.resolve(null)
  }

  private createDocumentFolder = async (parentId: string, directoryName: string): Promise<string> => {
    return this._documentListService.createDocument(parentId, DocumentType.FOLDER, directoryName);
  }

  public onDragOverFilesAndFolders(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onDragFile(file: any): void {
    this.movedFile = file;
  }

  protected readonly DocumentType = DocumentType;
  protected readonly ChipType = ChipType;
  protected readonly RouterLink = RouterLink;
}

