import { Injectable } from '@angular/core';
import {
  RulesApiService,
  WsRuleRequestDto,
  WsRuleResponseDto
} from "@fertirob/fertirob-api";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {ModuleDetailsService} from "./module-details.service";
import {FeedbackService} from "../../shared/services/feedback.service";
import {FeedbackType} from "../../shared/enums/feedback-type";
import {HelperService} from "../../shared/services/helper.service";

@Injectable({
  providedIn: 'root'
})
export class RuleDetailsService {

  constructor(private _helperService: HelperService, private _ruleApiService: RulesApiService, private _modulesDetailsService: ModuleDetailsService, private _feedbackService: FeedbackService) { }

  public deleteSingleRule = async (id: string): Promise<void> => {
    try {
      await this.deleteRule(id);

      this._feedbackService.setFeedback('snackbar.ruleDelete.success', FeedbackType.SUCCESS);
      await this._modulesDetailsService.fetchModule();
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }

  public deleteMultiRules = async (idList: string[]): Promise<void> => {
    try {
      for (const id of idList) {
        await this.deleteRule(id);
      }

      this._feedbackService.setFeedback('snackbar.ruleDeleteMulti.success', FeedbackType.SUCCESS);
      await this._modulesDetailsService.fetchModule();
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }

  private deleteRule = async (id: string): Promise<void> => {
    await firstValueFrom(this._ruleApiService.deleteRule(id))
  }

  public updateRule = async (id: string, rule: WsRuleRequestDto): Promise<WsRuleResponseDto> => {
    try {
      const result = await firstValueFrom(this._ruleApiService.updateRule(id, rule));
      await this._modulesDetailsService.fetchModule();
      this._feedbackService.setFeedback('snackbar.ruleUpdate.success', FeedbackType.SUCCESS);
      return Promise.resolve(result);
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.ruleUpdate.error', FeedbackType.ERROR);
      throw err
    }
  }

  public createRule = async (rule: WsRuleRequestDto): Promise<WsRuleResponseDto> => {
    try {
      const result = await firstValueFrom(this._ruleApiService.createRule(rule));
      await this._modulesDetailsService.fetchModule();
      this._feedbackService.setFeedback('snackbar.ruleCreate.success', FeedbackType.SUCCESS);
      return Promise.resolve(result);
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.ruleCreate.error', FeedbackType.ERROR);
      throw err
    }
  }
}
