import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {AsyncPipe} from '@angular/common';
import {CommentComponent} from '../../../shared/components/comment/comment.component';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {FormsModule} from '@angular/forms';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {MatIcon} from '@angular/material/icon';
import {MatInput, MatSuffix} from '@angular/material/input';
import {TranslatePipe} from '@ngx-translate/core';
import {map, Observable} from 'rxjs';
import {
  WsDocumentCommentResponseDto,
  WsDocumentCommentRequestDto
} from '@fertirob/fertirob-api';
import {CommentListService} from '../../services/comment-list.service';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';

@Component({
  selector: 'app-comment-list',
  standalone: true,
  imports: [
    AsyncPipe,
    CommentComponent,
    EmptyStateListComponent,
    FormsModule,
    HeadlineComponent,
    IconButtonComponent,
    MatIcon,
    MatInput,
    MatSuffix,
    TranslatePipe,
    SpinnerComponent
  ],
  templateUrl: './comment-list.component.html',
  styleUrl: './comment-list.component.scss'
})
export class CommentListComponent {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public comments$: Observable<WsDocumentCommentResponseDto[]> = this._commentListService.getComments();
  public isLoading$: Observable<boolean> = this._commentListService.isLoading;

  public newComment: string = '';
  public selectedCommentId: string = ''

  constructor(
    private _commentListService: CommentListService,
  ) {
  }

  get noComments(): Observable<boolean> {
    return this.comments$.pipe(
      map(comments => comments?.length === 0)
    );
  }

  setSelectedCommentId($event: string) {
    this.selectedCommentId = $event
  }

  onSendComment(): void {
    let comment: WsDocumentCommentRequestDto = {
      commenter: 'fertirob',
      comment: this.newComment,
      parentCommentId: ''
    }
    this._commentListService.createComment(comment).then(() => {
      this.newComment = '';
    });
  }

  onCloseComments(): void {
    this.onClose.emit(true);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
