import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {DocumentApiService, DocumentType, WsDocumentResponseDto, WsDocumentUpdateRequestDto} from '@fertirob/fertirob-api';
import {FeedbackService} from "../../shared/services/feedback.service";
import {FeedbackType} from "../../shared/enums/feedback-type";
import {UrlConstants} from '../../shared/constants/url-constants';
import {HelperService} from '../../shared/services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentListService {

  protected _pageSize = 10;
  protected _totalCount: any;
  private _documentList: BehaviorSubject<WsDocumentResponseDto[] | undefined> = new BehaviorSubject<WsDocumentResponseDto[] | undefined>(undefined);
  private _projectId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _parentDocumentId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _helperService: HelperService,
    private _documentApiService: DocumentApiService,
    private _feedbackService: FeedbackService
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchDocumentList();
  }

  public get getProjectId(): string {
    return this._projectId.getValue();
  }

  public setProjectId = (id: string): void => {
    this._projectId.next(id)
    this._parentDocumentId.next('');
  }

  public get getParentDocumentId(): string {
    return this._parentDocumentId.getValue();
  }

  public setParentDocumentId = (id: string): void => {
    this._parentDocumentId.next(id)
    this.fetchDocumentList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchDocumentList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchDocumentList();
  }

  public getDocumentList = (id?: string): Observable<WsDocumentResponseDto[] | undefined> => {
    this.fetchDocumentList();
    return this._documentList.asObservable();
  }

  public fetchDocumentList = async (): Promise<void> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._documentApiService.getDocuments(this.getPageSize, this.getCurrentPage, this.getProjectId, this.getParentDocumentId, false, this.getSearchTerm, 'response'));
      if (result.headers.get(UrlConstants.TotalCountHeader)) {
        this._totalCount = result.headers.get(UrlConstants.TotalCountHeader)
      }
      if (result.body) {
        this._documentList.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }
  }

  public markForDeleteSingleDocument = async (id: string): Promise<void> => {
    this._loading.next(true);
    try {
      await this.markForDeleteDocument(id);

      this._feedbackService.setFeedback('snackbar.delete.success', FeedbackType.SUCCESS);
      await this.fetchDocumentList();
      this._loading.next(false);
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.delete.failure', FeedbackType.ERROR);
      this._loading.next(false);
      return Promise.reject(err);
    }
  }

  public markForDeleteMultiDocuments = async (idList: string[]): Promise<void> => {
    this._loading.next(true);
    try {
      for (const id of idList) {
        await this.markForDeleteDocument(id);
      }

      this._feedbackService.setFeedback('snackbar.documentDeleteMulti.success', FeedbackType.SUCCESS);
      await this.fetchDocumentList();
      this._loading.next(false);
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      this._loading.next(false);
      throw err;
    }
  }

  private markForDeleteDocument = async (id: string): Promise<void> => {
    await firstValueFrom(this._documentApiService.removeDocument(id, false));
  }

  public createDocument = async (parentId: string, type: DocumentType, displayName: string, ownerEmail?: string, document?: Blob): Promise<string> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._documentApiService.createOrUpdateDocument(parentId, type, displayName, ownerEmail, document, 'response'));
      if (type === DocumentType.PDF) {
        this._feedbackService.setFeedback(result.status === 200 ? 'snackbar.fileUpdate.success' : 'snackbar.fileCreate.success', FeedbackType.SUCCESS);
      } else {
        this._feedbackService.setFeedback(result.status === 200 ? 'snackbar.folderUpdate.success' : 'snackbar.folderCreate.success', FeedbackType.SUCCESS);
      }
      this._loading.next(false);
      return Promise.resolve(result.body?.id ?? '');
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.fileCreate.failure', FeedbackType.ERROR);
      this._loading.next(false);
      throw err;
    }
  }

  public updateDocument = async (parentId: string, document: WsDocumentUpdateRequestDto): Promise<string> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._documentApiService.updateDocument(parentId, document));
      this._feedbackService.setFeedback('snackbar.documentCreate.success', FeedbackType.SUCCESS);
      if (result.type === DocumentType.PDF) {
        this._feedbackService.setFeedback('snackbar.fileUpdate.success', FeedbackType.SUCCESS);
      } else {
        this._feedbackService.setFeedback('snackbar.folderUpdate.success', FeedbackType.SUCCESS);
      }
      this._loading.next(false);
      await this.fetchDocumentList();
      return Promise.resolve(result.id ?? '');
    } catch (err) {
      this._loading.next(false);
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }
}
