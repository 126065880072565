import {Component, OnInit} from '@angular/core';
import {UiBreadCrumbItem} from "../../../shared/classes/ui-bread-crumb-item";
import {
  BreadCrumbNavigationComponent
} from "../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component";
import {PageLayoutComponent} from "../../../shared/components/page-layout/page-layout.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {firstValueFrom, map, Observable, Subject} from "rxjs";
import {TrashListService} from "../../services/trash-list.service";
import {ButtonType} from "../../../shared/enums/button-enum";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {AsyncPipe, DatePipe} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {
  CustomPaginatorComponent
} from "../../../shared/components/custom-paginator/custom-paginator.component";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {
  DeleteDocumentPermanentlyOverlayComponent
} from "../delete-document-permanently-overlay/delete-document-permanently-overlay.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TimeSettingOverlayComponent} from "../time-setting-overlay/time-setting-overlay.component";
import {InfoBannerComponent} from "../../../shared/components/info-banner/info-banner.component";
import {DocumentType, WsDocumentResponseDto, WsDocumentsRemovalConfigurationResponseDto} from '@fertirob/fertirob-api';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';
import {FileSizePipe} from '../../../shared/pipes/file-size.pipe';

@Component({
  selector: 'app-page-bin-list',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    PageLayoutComponent,
    HeadlineComponent,
    IconButtonComponent,
    TranslateModule,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatTooltip,
    DatePipe,
    MatButton,
    CustomPaginatorComponent,
    AsyncPipe,
    EmptyStateListComponent,
    InfoBannerComponent,
    SpinnerComponent,
    FileSizePipe
  ],
  templateUrl: './page-bin-list.component.html',
  styleUrl: './page-bin-list.component.scss'
})
export class PageBinListComponent implements OnInit {
  public breadCrumbItems: UiBreadCrumbItem[] = [];
  public trashList$: Observable<WsDocumentResponseDto[] | undefined> = this._trashListService.getTrashList();
  public storageTime$: Observable<WsDocumentsRemovalConfigurationResponseDto | undefined> = this._trashListService.getDocumentsRemovalConfiguration();
  public isLoading$: Observable<boolean> = this._trashListService.isLoading;

  private _notifier = new Subject<void>();

  constructor(private translate: TranslateService, private _trashListService: TrashListService, private _dialog: MatDialog) {
  }

  get pageSize(): number {
    return this._trashListService.getPageSize
  }

  get currentPage(): number {
    return this._trashListService.getCurrentPage
  }

  get totalCount(): number {
    return this._trashListService.getTotalCount
  }

  ngOnInit() {
    this.translate.get(['documents.documents', 'documents.trash.trash']).subscribe((translations => {
      this.breadCrumbItems = [
        {
          label: translations['documents.documents'],
          route: ['/', RouteConstants.routeDocuments]
        },
        {
          label: translations['documents.trash.trash'],
        }
      ];
    }))
  }


  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;

  public onShowDeleteOverlay(document: WsDocumentResponseDto) {
    const dialogRef = this._dialog.open(DeleteDocumentPermanentlyOverlayComponent);
    dialogRef.componentInstance.fileName = document.displayFileName ?? '';
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._trashListService.deletePermanently(document.id).then()
      }
    });
  }

  public onChangePage(page: number): void {
    this._trashListService.setCurrentPage(page)
  }

  get isEmpty(): Observable<boolean> {
    return this.trashList$.pipe(
      map(documents => documents?.length === 0)
    )
  }

  async openTimeSettings() {
    const dialogConfig = new MatDialogConfig();
    const storageTime = await firstValueFrom(this.storageTime$);
    dialogConfig.data = {storageTimeSetting: storageTime?.daysBeforeRemoval};

    const dialogRef = this._dialog.open(TimeSettingOverlayComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._trashListService.changeStorageTime(result.storageTimeSetting).then();
      }
    });
  }

  restoreDoc(id?: string) {
    if (!id) return;
    this._trashListService.restore(id).then()
  }

  getPath(document: WsDocumentResponseDto): string {
    if (!document.parentDocument) {
      return `${document.project.name}/${document.displayFileName}`;
    }
    const parentPath = this.getPath(document.parentDocument);
    return `${parentPath}/${document.displayFileName}`;
  }

  protected readonly DocumentType = DocumentType;
}
