import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BreadCrumbNavigationComponent} from '../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {PageLayoutComponent} from '../../../shared/components/page-layout/page-layout.component';
import {
  ModuleGeneralComponent
} from '../../../modules/components/module-general/module-general.component';
import {
  ModuleRulesComponent
} from '../../../modules/components/module-rules/module-rules.component';
import {TranslateModule} from '@ngx-translate/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {MatDialog} from '@angular/material/dialog';
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {ProjectDetailsService} from '../../services/project-details.service';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatOption, MatSelect} from '@angular/material/select';
import {LeavePageOverlayComponent} from '../../../shared/components/leave-page-overlay/leave-page-overlay.component';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {MatIcon} from '@angular/material/icon';
import {WsProjectUpdateRequestDto, WsProjectResponseDto} from '@fertirob/fertirob-api';

@Component({
  selector: 'app-project-general',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    HeadlineComponent,
    MatTab,
    MatTabGroup,
    PageLayoutComponent,
    ModuleGeneralComponent,
    ModuleRulesComponent,
    TranslateModule,
    RouterLink,
    InputWrapperComponent,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    ButtonComponent,
    MatIcon,
    MatSuffix
  ],
  templateUrl: './project-general.component.html',
  styleUrl: './project-general.component.scss'
})
export class ProjectGeneralComponent implements OnInit {
  @Input() project?: WsProjectResponseDto;
  protected readonly RouteConstants = RouteConstants;
  public detailForm: FormGroup;
  public formChanged = false;

  constructor(
    private _fb: FormBuilder,
    private _dialog: MatDialog,
    private _projectDetailsService: ProjectDetailsService
  ) {
    this.detailForm = this._fb.group({
      name: ['', Validators.required],
      description: [''],
      modules: [{value: '', disabled: true}],
    })
  }

  ngOnInit(): void {
    this.setUpForm();
  }

  public setUpForm(): void {
    let modules: string[] = [];
    this.project?.modules?.forEach((pm) => {
      if (pm.id) {
        modules.push(pm.id);
      }
    })
    this.detailForm.patchValue({
      name: this.project?.name,
      description: this.project?.description,
      modules: modules
    })

    this.onFormChange()
  }

  public onFormChange() {
    this.detailForm.valueChanges.subscribe(val => {
      this.formChanged = true;
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.formChanged) {
      const dialogRef = this._dialog.open(LeavePageOverlayComponent);
      return dialogRef.afterClosed().pipe(map(result => result === true));
    }
    return true;
  }

  public onSaveProject(): void {
    let saveProject: WsProjectUpdateRequestDto = {
      name: this.detailForm.value.name,
      description: this.detailForm.value.description
    }
    if (this.project?.id) {
      this._projectDetailsService.updateProject(this.project?.id, saveProject)
    }
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
