import {Injectable} from '@angular/core';
import {
  ParametersApiService,
  WsComponentRequestDto,
  WsModuleRequestDto,
  WsModuleResponseDto,
  WsParameterResponseDto
} from '@fertirob/fertirob-api';
import {firstValueFrom} from 'rxjs';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {FeedbackService} from '../../shared/services/feedback.service';
import {ComponentDetailsService} from './component-details.service';
import {WsParameterRequestDto} from '@fertirob/fertirob-api';
import {HelperService} from '../../shared/services/helper.service';
import {ModuleDetailsService} from './module-details.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterDetailsService {

  constructor(private _parameterApiService: ParametersApiService,
              private _helperService: HelperService,
              private _componentDetailsService: ComponentDetailsService,
              private _moduleDetailsService: ModuleDetailsService,
              private _feedbackService: FeedbackService) {
  }

  public saveParameter = async (parameter: WsParameterRequestDto): Promise<string> => {
    try {
      const result = await firstValueFrom(this._parameterApiService.createParameter(parameter))
      this._feedbackService.setFeedback('snackbar.parameterCreate.success', FeedbackType.SUCCESS);
      await this._componentDetailsService.fetchComponent();
      await this._moduleDetailsService.fetchModule();
      return Promise.resolve(result.id ?? '');
    } catch (err: any) {
      if (err.status === 400) {
        this._feedbackService.setFeedback('snackbar.parameterCreate.duplicate', FeedbackType.ERROR);
      } else {
        this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      }
      throw err;
    }
  }


  public deleteSingleParameter = async (id: string): Promise<void> => {
    try {
      await this.deleteParameter(id);

      this._feedbackService.setFeedback('snackbar.parameterDelete.success', FeedbackType.SUCCESS);
      await this._componentDetailsService.fetchComponent();
      await this._moduleDetailsService.fetchModule();
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }

  public deleteMultiParameters = async (idList: string[]): Promise<void> => {
    try {
      for (const id of idList) {
        await this.deleteParameter(id);
      }

      this._feedbackService.setFeedback('snackbar.parameterDeleteMulti.success', FeedbackType.SUCCESS);
      await this._componentDetailsService.fetchComponent();
      await this._moduleDetailsService.fetchModule();
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }

  private deleteParameter = async (id: string): Promise<void> => {
    await firstValueFrom(this._parameterApiService.deleteParameter(id))
  }

  public updateParameter = async (id: string, parameter: WsParameterRequestDto): Promise<WsParameterResponseDto> => {
    try {
      const result = await firstValueFrom(this._parameterApiService.updateParameter(id, parameter))
      this._feedbackService.setFeedback('snackbar.parameterUpdate.success', FeedbackType.SUCCESS);
      await this._componentDetailsService.fetchComponent();
      await this._moduleDetailsService.fetchModule();
      return Promise.resolve(result);
    } catch (err: any) {
      if (err.status === 400) {
        this._feedbackService.setFeedback('snackbar.parameterCreate.duplicate', FeedbackType.ERROR);
      } else {
        this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      }
      throw err;
    }
  }
}
