import {Component, Input} from '@angular/core';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {UiDashboardItem} from '../../classes/ui-dashboard-item';
import {DatePipe} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {TimeAgoPipe} from '../../../shared/pipes/time-ago.pipe';

@Component({
  selector: 'app-dashboard-item',
  standalone: true,
  imports: [
    ButtonComponent,
    DatePipe,
    TranslateModule,
    RouterLink,
    TimeAgoPipe
  ],
  templateUrl: './dashboard-item.component.html',
  styleUrl: './dashboard-item.component.scss'
})
export class DashboardItemComponent {
  @Input() dashboardItem: UiDashboardItem = {amount: 0, description: '', headline: '', icon: IconEnum.ADD};
  @Input() isSubItem: boolean = false;
  @Input() isLoading: boolean | null = false;
}
