<div class="version-list">
  <app-headline [title]="'default.version' | translate">
    <app-icon-button [iconName]="IconEnum.CLOSE" [type]="ButtonType.PLAIN"
                     (click)="onCloseVersions()"></app-icon-button>
  </app-headline>
  <div class="version-content">
    @if (noVersions | async) {
      <app-empty-state-list [emptyStateText]="'documents.versionsEmptyState' | translate"></app-empty-state-list>
    } @else {
      @for (version of (versions$ | async); track version; let last = $last) {
        <app-version [version]="version" [noLine]="last"></app-version>
      }
    }
  </div>
</div>
