@if (!module?.readonly) {
  <app-headline [title]="(ruleValueToEdit ? 'modules.editRule' : 'modules.addNewRule') | translate"></app-headline>
  <form class="horizontal-form align-top" [formGroup]="ruleForm">
    <div class="input-wrapper">
      <mat-form-field>
        <input type="text" [placeholder]="'modules.parameter' | translate"
               matInput
               formControlName="parameter"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          @for (option of parameterFilteredOptions | async; track $index) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-autocomplete>
        <mat-icon matSuffix class="material-icons">{{ IconEnum.ARROW_DOWN }}</mat-icon>
      </mat-form-field>
      @if (invalidParameter) {
        <p class="hint error">{{ 'modules.addNewRuleParameterError' | translate }}</p>
      }
    </div>
    <p class="material-icons equal">{{ IconEnum.EQUAL }}</p>
    <div class="tagify-wrapper">
      @if (ruleValueToEdit) {
        <app-tagify-input [parameterList]="parameterList" [resetRule]="onResetRule" [ruleValueToEdit]="ruleValueToEdit"
                          (onRuleChange)="onRuleChange($event)"></app-tagify-input>
      } @else {
        <app-tagify-input [parameterList]="parameterList" [resetRule]="onResetRule"
                          (onRuleChange)="onRuleChange($event)"></app-tagify-input>
      }
      @if (invalidFormula) {
        <p class="hint error">{{ 'modules.addNewRuleError' | translate }}</p>
      }
      <p class="hint">{{ 'modules.addNewRuleHint' | translate }}</p>
    </div>
    @if (ruleForm.dirty || ruleValueToEdit) {
      <app-button (click)="resetRuleForm()" [type]="ButtonType.SECONDARY" [label]="'default.cancel' | translate"></app-button>
    }
    <app-button [isDisabled]="disableAddRuleButton"
                (click)="onAddRule()"
                [label]="(ruleValueToEdit ? 'default.save' : 'default.create') | translate"></app-button>
  </form>
}

<app-headline [title]="'modules.rulesList' | translate">
  <app-button [isSmall]="true"
              [isDisabled]="isEmpty"
              [label]="'select.selectRules' | translate"
              (click)="toggleSelectionMode(true)"
              [type]="ButtonType.SECONDARY"></app-button>
</app-headline>
@if (selectionMode) {
  <app-selection-bar [selectedAmount]="checkedRules.length"
                     (onChangeSelectionMode)="toggleSelectionMode(false)"
                     (onTriggerDelete)="deleteOverlay()"
                     (onTriggerDownload)="onDownload()"
  ></app-selection-bar>
}
@if (isEmpty) {
  <app-empty-state-list
    [emptyStateText]="'modules.modulesRulesEmptyState' | translate"></app-empty-state-list>
} @else {
  <table matSort (matSortChange)="sortData($event)">
    <tbody>
    <tr>
      <th>
        <div class="flex-content-wrapper">
          @if (!module?.readonly && selectionMode) {
            <mat-checkbox (change)="onCheckAll($event.checked)"
                          [checked]="areAllChecked()"
                          [indeterminate]="areSomeChecked()">
            </mat-checkbox>
          }
          {{ 'default.rule' | translate }}
        </div>
      </th>
      <th class="table-head-sort"
          mat-sort-header="lastModified">{{ 'default.updatedDate' | translate }}
      </th>
      <th>{{ 'default.edit' | translate }}</th>
    </tr>
      @for (rule of module?.rules; track rule.id) {
        <tr>
          <td>
            <div class="flex-content-wrapper">
              @if (!module?.readonly && selectionMode) {
                <mat-checkbox [checked]="isChecked(rule.id)"
                              (change)="onCheckboxCheck($event.checked, rule.id)">
                </mat-checkbox>
              }
              {{ 'modules.rulesEquals' | translate: {firstArgument: rule.parameterName, secondArgument: rule.value} }}
            </div>
          </td>
          <td>{{ rule.createdDate | date }}</td>
          <td>
            <div class="flex-content-wrapper">
              <app-icon-button [isDisabled]="module?.readonly" [type]="ButtonType.PLAIN"
                               [iconName]="IconEnum.DELETE" (click)="deleteOverlay(rule)"></app-icon-button>
              <app-icon-button (click)="onEditRule(rule)" [isDisabled]="module?.readonly || ruleValueToEdit !== undefined"
                               [type]="ButtonType.PLAIN"
                               [iconName]="IconEnum.EDIT"></app-icon-button>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
}


