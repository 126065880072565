@if (comment) {
  <div class="comment-wrapper" [class.hideBorder]="isSubComment">
    <div class="comment-header">
      <p class="email">{{ comment.commenter }}</p>
      <p class="date">{{ comment.createdDate | date }}</p>
    </div>
    <p class="comment">{{ comment.comment }}</p>
    <div class="comment-footer">
      <app-button [isSmall]="true" [label]="'comments.response' | translate"
                  (click)="toggleWriteComment(comment.id)"></app-button>
      @if (comment.replies && comment.replies.length > 0) {
        <button class="text-link"
                (click)="toggleShowComments()">{{ (comment.replies.length === 1 ? 'comments.oneComment' : 'comments.amountOfComments') | translate: {amount: comment.replies.length} }}
        </button>
      }
    </div>
    @if (showSubComments) {
      <div class="sub-comment-wrapper">
        <div class="line" [class.showLines]="true"></div>
        <div class="sub-comments">
          @for (comment of comment.replies; track comment) {
            <div class="sub-comment">
              <app-comment [comment]="comment" [isSubComment]="true" (triggerSelected)="toggleWriteComment($event)" [isSelectedComment]="isSelectedComment"></app-comment>
            </div>
          }
        </div>
      </div>
    }
    @if (writeComment && isSelectedComment===comment.id) {
      <div class="input-wrapper send">
        <div class="textarea-wrapper small-textarea">
          <textarea matInput class="comment-textarea" [(ngModel)]="newComment"
                    [placeholder]="'comments.writeComment' | translate"></textarea>
          <button class="send-button" matSuffix [disabled]="!newComment" (click)="onSendComment()">
            <mat-icon class="material-icons">{{ IconEnum.SEND }}</mat-icon>
          </button>
        </div>
      </div>
    }
  </div>
}
