import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StatisticsApiService, WsStatisticsResponseDto} from '@fertirob/fertirob-api';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _statisticsApiService: StatisticsApiService) {
  }

  public getDashboardInfos = (): Observable<WsStatisticsResponseDto> => {
    return this._statisticsApiService.getStatistics();
  }
}
