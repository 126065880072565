<div class="dashboard-tile" [class.sub-item]="isSubItem" [class.has-sub-item]="dashboardItem.subDashboardItems">
  <div class="dashboard-tile-content">
    <div class="header">
      <h2 class="amount" [class.loading-text]="isLoading">{{ dashboardItem.amount }}</h2>
      <div class="info-wrapper">
        <div class="headline-wrapper">
          <span class="material-icons">{{ dashboardItem.icon }}</span>
          <p class="headline">{{ dashboardItem.headline | translate }}</p>
        </div>
        @if (!isSubItem) {
          <p class="last-updated">
            {{ 'dashboard.lastUpdated' | translate }} <br>
            <span [class.loading-text]="isLoading">
               @if (dashboardItem.lastUpdated) {
                 {{ dashboardItem.lastUpdated | timeAgo }}
               } @else {
                 <i>{{ 'dashboard.notUpdated' | translate }}</i>
               }
            </span>
          </p>
        }
      </div>
    </div>
    <p class="description">{{ dashboardItem.description | translate }}</p>
  </div>
  @for (item of dashboardItem.subDashboardItems; track item) {
    <app-dashboard-item [dashboardItem]="item" [isSubItem]="true" [isLoading]="isLoading"></app-dashboard-item>
  }
  @if (dashboardItem.route) {
    <div class="button-wrapper">
      <app-button [routerLink]="['/', dashboardItem.route]" [label]="dashboardItem.headline | translate"></app-button>
    </div>
  }
</div>
