<form [formGroup]="detailForm" class="form details-form">
  <div>
    <app-input-wrapper [id]="'name'" [label]="'default.name' | translate" [control]="detailForm.controls['name']">
      <input type="text" id="name" formControlName="name"
             [placeholder]="'default.inputPlaceholder' | translate">
    </app-input-wrapper>
    <div class="input-wrapper">
      <label>{{ 'modules.modules' | translate }}</label>
      <mat-form-field>
        <mat-select formControlName="modules" multiple>
          @for (pm of project?.modules; track pm) {
            <mat-option [value]="pm.id">{{ pm.name }}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix class="material-icons">{{ IconEnum.ARROW_DOWN }}</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <app-input-wrapper [id]="'description'" [label]="'default.description' | translate" [control]="detailForm.controls['description']">
              <textarea id="description" formControlName="description"
                        [placeholder]="'default.inputPlaceholder' | translate"></textarea>
  </app-input-wrapper>
</form>
<div class="button-wrapper">
  <app-button [label]="'default.save' | translate" [isDisabled]="detailForm.invalid || !detailForm.dirty" [type]="ButtonType.PRIMARY" (click)="onSaveProject()"></app-button>
</div>
