import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {
  ComponentsApiService,
  WsComponentRequestDto,
  WsComponentResponseDto
} from '@fertirob/fertirob-api';
import {FeedbackService} from '../../shared/services/feedback.service';
import {HelperService} from '../../shared/services/helper.service';
import {ModuleDetailsService} from './module-details.service';
import {FeedbackType} from "../../shared/enums/feedback-type";

@Injectable({
  providedIn: 'root'
})
export class ComponentDetailsService {

  private _component: BehaviorSubject<WsComponentResponseDto | undefined>;

  constructor(private _componentsApiService: ComponentsApiService,
              private _helperService: HelperService,
              private _moduleDetailsService: ModuleDetailsService,
              private _feedbackService: FeedbackService) {
    this._component = new BehaviorSubject<WsComponentResponseDto | undefined>(undefined)
  }

  public getComponent = (): Observable<WsComponentResponseDto | undefined> => {
    return this._component.asObservable();
  }

  public resetComponent = (): void => {
    this._component.next(undefined);
  }

  public fetchComponent = async (id?: string): Promise<void> => {
    if (id === this._component.getValue()?.id) {
      return
    }
    if (!id) {
      id = this._component.getValue()?.id??'';
    }
    this._component.next(undefined);
    try {
      const result = await firstValueFrom(this._componentsApiService.getComponent(id))

      if (result) {
        this._component.next(result)
      }
    } catch (err) {
      throw err
    }
  }

  public createComponent = async (parentId: string, componentData: WsComponentRequestDto): Promise<WsComponentResponseDto> => {
    try {
      componentData.parentId = parentId;
      const result = await firstValueFrom(this._componentsApiService.createComponent(componentData));
      this._feedbackService.setFeedback('snackbar.componentCreate.success', FeedbackType.SUCCESS);
      await this.fetchComponent();
      await this._moduleDetailsService.fetchModule();
      return Promise.resolve(result)
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err
    }
  }

  public deleteComponent = async (id: string): Promise<void> => {
    try {
      this._feedbackService.setFeedback('snackbar.componentDelete.success', FeedbackType.SUCCESS);
      const result = await firstValueFrom(this._componentsApiService.deleteComponent(id));
      await this.fetchComponent();
      await this._moduleDetailsService.fetchModule();
      return Promise.resolve(result)
    } catch (err) {
      console.log(err)
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err))
      throw err
    }
  }

  public updateComponent = async (id: string, componentData: WsComponentRequestDto): Promise<WsComponentResponseDto> => {
    try {
      const result = await firstValueFrom(this._componentsApiService.updateComponent(id, componentData));
      this._feedbackService.setFeedback('snackbar.componentUpdate.success', FeedbackType.SUCCESS);
      await this.fetchComponent();
      return Promise.resolve(result);
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err
    }
  }
}
