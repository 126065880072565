<div class="info-content">
  <div class="left-content">
    <app-headline [title]="'default.details' | translate"></app-headline>
    <!--TODO: remove class="form details-form" when adding right-content back in-->
    <form [formGroup]="componentForm" class="form details-form">
      <app-input-wrapper [id]="'name'" [label]="'default.name' | translate"
                         [control]="componentForm.controls['name']">
        <input type="text" id="name" formControlName="name"
               [placeholder]="'default.inputPlaceholder' | translate">
      </app-input-wrapper>
      <app-input-wrapper [id]="'description'" [label]="'default.description' | translate"
                         [control]="componentForm.controls['description']">
            <textarea type="text" id="description" formControlName=description
                      [placeholder]="'default.inputPlaceholder' | translate"> </textarea>
      </app-input-wrapper>
    </form>
    <!--TODO: remove button-wrapper when adding right-content back in-->
    <div class="button-wrapper mid-section">
      <app-button (click)="onSaveComponent()" [label]="'default.save' | translate"
                  [isDisabled]="componentForm.invalid || !componentForm.dirty || isReadOnlyMode"></app-button>
    </div>
  </div>
  <!--<div class="right-content">
    <app-headline [title]="'default.technicalDrawings' | translate">
      <app-button [isDisabled]="isReadOnlyMode" [label]="'default.add' | translate" [isSmall]="true"
                  [type]="ButtonType.SECONDARY"></app-button>
    </app-headline>
    <app-drag-and-drop [isDisabled]="isReadOnlyMode"></app-drag-and-drop>
    <div class="button-wrapper mid-section-top">
      <app-button (click)="onSaveComponent()" [label]="'default.save' | translate"
                  [isDisabled]="componentForm.invalid || !componentForm.dirty || isReadOnlyMode"></app-button>
    </div>
  </div>-->
</div>
@if (isAssembly(component?.type)) {
  <app-headline [title]="'modules.components' | translate">
    <app-button [isDisabled]="isReadOnlyMode" [label]="'modules.newComponent' | translate" [isSmall]="true"
                [type]="ButtonType.SECONDARY" (click)="onShowAddComponentOverlay()"></app-button>
  </app-headline>
<!--  <app-filter-option-wrapper
    [searchPlaceholder]="'modules.searchComponents' | translate"
    [filterOptions]="filterOptions"
    (searchTerm)="onSearchTermChange($event)"
    (selectedOption)="onFilterOptionChange($event)"
  ></app-filter-option-wrapper>-->
  @if (isEmpty) {
    <app-empty-state-list
      [emptyStateText]="'modules.componentEmptyState' | translate"></app-empty-state-list>
  } @else {
    <div class="table table-grid">
      <div class="table-row table-header-row">
        <div class="table-cell">{{ 'default.name' | translate }}</div>
        <div class="table-cell">{{ 'default.updatedDate' | translate }}</div>
        <div class="table-cell">{{ 'default.edit' | translate }}</div>
      </div>
      <app-table-info-row [element]="component?.name"></app-table-info-row>
      @for (component of component?.subComponents; track component.id) {
        @if (isAssembly(component?.type)) {
          <app-assembly-table-row [assembly]="component" [offset]="-1"
                                  [enableDelete]="!isReadOnlyMode"></app-assembly-table-row>
        } @else {
          <app-part-table-row [part]="component" [offset]="-1"
                              [enableDelete]="!isReadOnlyMode"></app-part-table-row>
        }
      }
    </div>
  }
}
