import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {
  DocumentApiService,
  WsDocumentResponseDto,
  DocumentsRemovalConfigurationApiService,
  WsDocumentsRemovalConfigurationRequestDto,
  WsDocumentsRemovalConfigurationResponseDto, WsDocumentUpdateRequestDto
} from "@fertirob/fertirob-api";
import {FeedbackService} from "../../shared/services/feedback.service";
import {FeedbackType} from "../../shared/enums/feedback-type";
import {UrlConstants} from '../../shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class TrashListService {
  protected _pageSize: number = 10;
  protected _totalCount: any;
  private _trashList: BehaviorSubject<WsDocumentResponseDto[] | undefined> = new BehaviorSubject<WsDocumentResponseDto[] | undefined>(undefined);
  private _documentsRemovalConfiguration: BehaviorSubject<WsDocumentsRemovalConfigurationResponseDto | undefined> = new BehaviorSubject<WsDocumentsRemovalConfigurationResponseDto | undefined>(undefined);
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _feedbackService: FeedbackService,
    private _documentApiService: DocumentApiService,
    private _documentsRemovalConfigurationApiService: DocumentsRemovalConfigurationApiService,
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize;
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchTrashList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchTrashList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchTrashList();
  }

  public getDocumentsRemovalConfiguration = (id?: string): Observable<WsDocumentsRemovalConfigurationResponseDto | undefined> => {
    this.fetchDocumentsRemovalConfiguration();
    return this._documentsRemovalConfiguration!.asObservable();
  }

  public fetchDocumentsRemovalConfiguration = async (): Promise<void> => {
    try {
      const result = await firstValueFrom(this._documentsRemovalConfigurationApiService.getDocumentsRemovalConfiguration());
      if (result) {
        this._documentsRemovalConfiguration.next(result);
      }
    } catch (err) {
      throw err;
    }
  }

  public getTrashList = (id?: string): Observable<WsDocumentResponseDto[] | undefined> => {
    this.fetchTrashList();
    return this._trashList!.asObservable();
  }

  public fetchTrashList = async (): Promise<void> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._documentApiService.getDocuments(this.getPageSize, this.getCurrentPage, "", "", true, this.getSearchTerm, 'response'));
      if (result.headers.get(UrlConstants.TotalCountHeader)) {
        this._totalCount = result.headers.get(UrlConstants.TotalCountHeader)
      }
      if (result.body) {
        this._trashList.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }
  }

  public restore = async (id: string): Promise<string> => {
    let document: WsDocumentUpdateRequestDto = {
      markedForRemoval: false
    }
    try {
      const result = await firstValueFrom(this._documentApiService.updateDocument(id, document));
      this._feedbackService.setFeedback('snackbar.restoreFile.success', FeedbackType.SUCCESS)
      await this.fetchTrashList();
      return Promise.resolve(result.id);
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.restoreFile.failure', FeedbackType.ERROR)
      throw err;
    }
  }

  public deletePermanently = async (id: string): Promise<string> => {
    try {
      const result = await firstValueFrom(this._documentApiService.removeDocument(id, true));
      this._feedbackService.setFeedback('snackbar.deletePermanently.success', FeedbackType.SUCCESS)
      await this.fetchTrashList();
      return Promise.resolve(result.id);
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.deletePermanently.failure', FeedbackType.ERROR)
      throw err;
    }
  }

  public changeStorageTime = async (time: number): Promise<void> => {
    try {
      let documentsRemovalConfiguration: WsDocumentsRemovalConfigurationRequestDto = {
        daysBeforeRemoval: time
      }
      const result = await firstValueFrom(this._documentsRemovalConfigurationApiService.createOrUpdateDocumentsRemovalConfiguration(documentsRemovalConfiguration));
      this._documentsRemovalConfiguration.next(result)
      this._feedbackService.setFeedback('snackbar.updateTimeSettings.success', FeedbackType.SUCCESS)
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.updateTimeSettings.failure', FeedbackType.ERROR)
      throw err;
    }
  }
}
