import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {

  transform(bytes: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1000));
    const size = parseFloat((bytes / Math.pow(1000, i)).toFixed(0));
    return `${size} ${sizes[i]}`;
  }
}
