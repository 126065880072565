import {Component, Input, OnInit} from '@angular/core';
import {PageLayoutComponent} from '../../../shared/components/page-layout/page-layout.component';
import {DashboardItemComponent} from '../dashboard-item/dashboard-item.component';
import {UiDashboardItem} from '../../classes/ui-dashboard-item';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {AsyncPipe} from '@angular/common';
import {ProjectTableRowComponent} from '../../../projects/components/project-table-row/project-table-row.component';
import {DashboardService} from '../../services/dashboard.service';
import {Observable, Subject, takeUntil} from 'rxjs';
import {WsStatisticsResponseDto} from '@fertirob/fertirob-api';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';

@Component({
  selector: 'app-page-dashboard',
  standalone: true,
  imports: [
    PageLayoutComponent,
    DashboardItemComponent,
    AsyncPipe,
    ProjectTableRowComponent,
    SpinnerComponent
  ],
  templateUrl: './page-dashboard.component.html',
  styleUrl: './page-dashboard.component.scss'
})
export class PageDashboardComponent implements OnInit {
  public dashboardInfos$: Observable<WsStatisticsResponseDto | undefined> = this._dashboardService.getDashboardInfos();
  public isLoading$: Observable<boolean> = this._dashboardService.isLoading;

  public projectItem: UiDashboardItem = {
    amount: 0,
    headline: 'projects.projects',
    icon: IconEnum.PROJECT,
    description: 'dashboard.projectDescription',
    route: RouteConstants.routeProjects,
    lastUpdated: undefined
  };
  public documentItem: UiDashboardItem = {
    amount: 0,
    headline: 'documents.documents',
    icon: IconEnum.DOCUMENTS,
    description: 'dashboard.documentsDescription',
    route: RouteConstants.routeDocuments,
    lastUpdated: undefined
  };
  public partItem: UiDashboardItem = {
    amount: 0,
    headline: 'dashboard.partsTotalHeadline',
    icon: IconEnum.PART,
    description: 'dashboard.partsDescription'
  };
  public assemblyGroupItem: UiDashboardItem = {
    amount: 0,
    headline: 'dashboard.assemblyGroupTotalHeadline',
    icon: IconEnum.ASSEMBLY_GROUP,
    description: 'dashboard.assemblyGroupDescription'
  };
  public rulesItem: UiDashboardItem = {
    amount: 0,
    headline: 'dashboard.rulesTotalHeadline',
    icon: IconEnum.RULES,
    description: 'dashboard.rulesDescription'
  };
  public moduleItem: UiDashboardItem = {
    amount: 0,
    headline: 'modules.modules',
    icon: IconEnum.PRODUCTION_MODULE,
    description: 'dashboard.modulesDescription',
    route: RouteConstants.routeModules,
    lastUpdated: undefined,
    subDashboardItems: [
      this.partItem,
      this.assemblyGroupItem,
      this.rulesItem
    ]
  };
  public dashboardItems: UiDashboardItem[] = [
    this.projectItem,
    this.documentItem,
    this.moduleItem
  ];

  private _notifier = new Subject<void>();

  constructor(private _dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.getDashboardInfos();
  }

  private getDashboardInfos(): void {
    this.dashboardInfos$.pipe(takeUntil(this._notifier)).subscribe((result: WsStatisticsResponseDto | undefined) => {
      this.projectItem.amount = result?.projects?.totalCount??0;
      this.projectItem.lastUpdated = result?.projects?.updatedDate;
      this.documentItem.amount = result?.documents?.totalCount??0;
      this.documentItem.lastUpdated = result?.documents?.updatedDate;
      this.moduleItem.amount = result?.modules?.moduleCount??0;
      this.moduleItem.lastUpdated = result?.modules?.moduleUpdatedDate;
      this.partItem.amount = result?.modules?.partCount??0;
      this.assemblyGroupItem.amount = result?.modules?.assemblyCount??0;
      this.rulesItem.amount = result?.modules?.ruleCount??0;
    })
  }
}
