import {Routes} from '@angular/router';
import {ExamplePageComponent} from './shared/components/example-page/example-page.component';
import {RouteConstants} from "./shared/constants/route-constants";
import {
  PageModuleDetailsComponent
} from "./modules/components/page-module-details/page-module-details.component";
import {
  PageModuleListComponent
} from './modules/components/page-module-list/page-module-list.component';
import {PageProjectListComponent} from './projects/components/page-project-list/page-project-list.component';
import {hasUnsavedChangesGuard} from './shared/services/can-deactivate-guard.service';
import {PageProjectDetailsComponent} from './projects/components/page-project-details/page-project-details.component';
import {
  PageComponentDetailsComponent
} from "./modules/components/page-component-details/page-component-details.component";
import {PageErrorComponent} from "./core/components/page-error/page-error.component";
import {
  PageDocumentProjectListComponent
} from "./documents/components/page-document-project-list/page-document-project-list.component";
import {PageProjectDocumentListComponent} from "./documents/components/page-project-document-list/page-project-document-list.component";
import {PageBinListComponent} from "./documents/components/page-bin-list/page-bin-list.component";
import {PageDashboardComponent} from './dashboard/components/page-dashboard/page-dashboard.component';
import {CanActivateUrlIdCheckGuardService} from './shared/services/url-id-check-guard.service';

export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: RouteConstants.routeDashboard},
  {path: RouteConstants.routeDashboard, component: PageDashboardComponent},
  {
    path: RouteConstants.routeModules,
    children: [
      {
        path: RouteConstants.routeIdModule,
        canActivate: [CanActivateUrlIdCheckGuardService],
        children: [
          {path: RouteConstants.routeGeneral, component: PageModuleDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
          {path: RouteConstants.routeRules, component: PageModuleDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
          {path: RouteConstants.routeParameters, component: PageModuleDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
          {path: '', pathMatch: 'full', redirectTo: RouteConstants.routeGeneral},
          {
            path: RouteConstants.routeIdComponent,
            canActivate: [CanActivateUrlIdCheckGuardService],
            children: [
              {path: RouteConstants.routeGeneral, component: PageComponentDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
              {path: RouteConstants.routeParameters, component: PageComponentDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
              {path: '', pathMatch: 'full', redirectTo: RouteConstants.routeGeneral}
            ]
          }
        ]
      },
      {path: '', component: PageModuleListComponent},
    ]
  },
  {
    path: RouteConstants.routeProjects,
    children: [
      {
        path: RouteConstants.routeId,
        canActivate: [CanActivateUrlIdCheckGuardService],
        children: [
          {path: RouteConstants.routeGeneral, component: PageProjectDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
          {path: RouteConstants.routeInputs, component: PageProjectDetailsComponent, canDeactivate: [hasUnsavedChangesGuard]},
          {path: '', pathMatch: 'full', redirectTo: RouteConstants.routeGeneral}
        ]
      },
      {path: '', component: PageProjectListComponent}
    ]
  },
  {
    path: RouteConstants.routeDocuments,
    children: [
      {path: RouteConstants.routeTrash, component: PageBinListComponent},
      {
        path: RouteConstants.routeId,
        canActivate: [CanActivateUrlIdCheckGuardService],
        children: [
          {
            path: RouteConstants.routeIdDocument,
            canActivate: [CanActivateUrlIdCheckGuardService],
            component: PageProjectDocumentListComponent
          },
          {path: '', component: PageProjectDocumentListComponent},
        ]
      },
      {path: '', component: PageDocumentProjectListComponent}
    ]
  },
  {path: RouteConstants.routeAuthorizationError, component: PageErrorComponent},
  {path: '**', component: PageErrorComponent}
];
